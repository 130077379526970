
@use "../variables" as *;
@use "../mixins/" as *;


header{
    padding-top: 25px;
    padding-bottom: 45px;
    @include max(767) {
        padding: 25px 0;
        img{max-width: 120px;}
    }
}