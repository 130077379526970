.tabs {
    display: flex;
    gap: 4px;
    align-items: center;

    span {
        color: #FD0095;
        font-family: Manrope;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 12px */
    }
}

.tip{font-size: 16px; font-weight: 400; color: #FD0095; background: url(../images/bulb.svg) no-repeat left center / 15px; padding: 2px 0 2px 20px;}