html {
  font-family: Manrope, sans-serif;
  font-size: 10px;
  overflow: hidden scroll !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}

body {
  background-color: #fff;
  height: auto !important;
  overflow-x: hidden !important;
}

img {
  max-width: 100%;
  height: auto;
}

p, span, a, table, div, article, figcaption {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Manrope, sans-serif;
  line-height: 1.4;
}

h1, .h1 {
  font-size: 4.6rem;
}

h2, .h2 {
  font-size: 3.5rem;
}

h3, .h3 {
  font-size: 3rem;
}

h4, .h4 {
  font-size: 2.5rem;
}

h5, .h5 {
  font-size: 2.25rem;
}

h6, .h6 {
  font-size: 2rem;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 320px) {
  .container {
    max-width: 100%;
  }
}

@media (width >= 576px) {
  .container {
    max-width: 576px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 992px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.row.aligin-center {
  align-items: center;
}

.row.justify-center {
  justify-content: center;
}

.row.align-bottom {
  align-items: flex-end;
}

.row.align-top {
  align-items: flex-start;
}

.row.align-self-start {
  align-self: flex-start;
}

[class^="col"] {
  -moz-box: 0;
  flex: 0 0 100%;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 992px) {
  .col1 {
    -moz-box: 0;
    flex: 0 0 .833333%;
    max-width: .833333%;
  }
}

.col2 {
  max-width: 16.6667%;
}

@media (width >= 992px) {
  .col2 {
    -moz-box: 0;
    flex: 0 0 16.6667%;
  }

  .col3 {
    -moz-box: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col4, .col4md {
    -moz-box: 0;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col5 {
    -moz-box: 0;
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col6 {
    -moz-box: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .col6md {
    -moz-box: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (width <= 767px) {
  .col6sm {
    -moz-box: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col7 {
  -moz-box: 0;
  flex: 0 0 58.3333%;
}

@media (width >= 992px) {
  .col8 {
    -moz-box: 0;
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .col8lg {
    -moz-box: 0;
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
}

@media (width >= 992px) {
  .col9 {
    -moz-box: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col10 {
    -moz-box: 0;
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col11 {
    -moz-box: 0;
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }
}

@media (width <= 575px) {
  .colxs12 {
    -moz-box: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 1200px) {
  .offsetxl1 {
    margin-left: 8.33333px;
  }
}

@media (width >= 992px) {
  .order-lg-1 {
    -webkit-box-original-group: 2;
    order: 1;
  }

  .order-lg-12 {
    --webkit-box-original-group: 13;
    order: 12;
  }
}

footer {
  padding: 70px 0;
}

@media (width <= 991px) {
  footer {
    padding: 40px 0;
  }
}

footer .txt {
  align-items: center;
  display: flex;
}

@media (width <= 767px) {
  footer .txt {
    flex-direction: column;
    align-items: flex-start;
  }
}

footer .txt p {
  font-size: 16px;
  display: inline-block;
}

@media (width <= 1199px) {
  footer .txt p {
    font-size: 14px;
  }
}

@media (width <= 767px) {
  footer .txt p {
    padding-bottom: 20px;
  }
}

footer .txt ul {
  margin: 0;
  display: flex;
}

@media (width <= 575px) {
  footer .txt ul {
    display: block;
  }
}

footer .txt ul li {
  padding-left: 15px;
  list-style: none;
}

@media (width <= 767px) {
  footer .txt ul li {
    padding-left: 0;
    padding-right: 15px;
  }
}

@media (width <= 575px) {
  footer .txt ul li {
    display: inline-block;
  }
}

footer .txt ul li a {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  display: block;
}

@media (width <= 1199px) {
  footer .txt ul li a {
    font-size: 10px;
  }
}

footer .social-icons {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  footer .social-icons {
    justify-content: flex-start;
    margin-top: 30px;
  }
}

footer .social-icons li {
  list-style: none;
}

footer .social-icons li + li {
  margin-left: 20px;
}

footer .social-icons li svg {
  width: 20px;
  height: auto;
}

footer .social-icons li svg path {
  fill: #fd0095;
  transition: all .25s ease-out;
}

footer .social-icons li a {
  display: block;
}

footer .social-icons li a:hover svg path {
  fill: #000;
}

header {
  padding-top: 25px;
  padding-bottom: 45px;
}

@media (width <= 767px) {
  header {
    padding: 25px 0;
  }

  header img {
    max-width: 120px;
  }
}

.svg-mask, .svg-mask-2 {
  stroke-dasharray: 3000;
  stroke-dashoffset: 3000px;
  transition: stroke-dashoffset 2s ease-in-out;
}

.svg-mask-lr {
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000px;
  transition: stroke-dashoffset 2s ease-in-out;
}

.tabs {
  align-items: center;
  gap: 4px;
  display: flex;
}

.tabs span {
  color: #fd0095;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 15px no-repeat;
  padding: 2px 0 2px 20px;
  font-size: 16px;
  font-weight: 400;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all .15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: .8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all .15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .m8 .stars, .m8 .sparkles, .tree-1 .stars, .tree-1 .sparkles, .tree-2 .stars, .tree-2 .sparkles, .tree-3 .stars, .tree-3 .sparkles, .tree-4 .stars, .tree-4 .sparkles, .tree-5 .stars, .tree-5 .sparkles, .m8 .plantAndLeaves, .tree-1 .plantAndLeaves, .tree-2 .plantAndLeaves, .tree-3 .plantAndLeaves, .tree-4 .plantAndLeaves, .tree-5 .plantAndLeaves {
    opacity: 0;
    transition: opacity 3s ease-in;
  }

  .m8 .animate .plantAndLeaves, .tree-1 .animate .plantAndLeaves, .tree-2 .animate .plantAndLeaves, .tree-3 .animate .plantAndLeaves, .tree-4 .animate .plantAndLeaves, .tree-5 .animate .plantAndLeaves, .m8 .animate .stars, .tree-1 .animate .stars, .tree-2 .animate .stars, .tree-3 .animate .stars, .tree-4 .animate .stars, .tree-5 .animate .stars {
    opacity: 1;
    transition: opacity 3s ease-in;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .m8 .stem, .tree-1 .stem, .tree-2 .stem, .tree-3 .stem, .tree-4 .stem, .tree-5 .stem {
    stroke-width: 2.44px;
    stroke-dasharray: 600;
    stroke-dashoffset: 600px;
  }

  .m8 .branch-small, .tree-1 .branch-small, .tree-2 .branch-small, .tree-3 .branch-small, .tree-4 .branch-small, .tree-5 .branch-small {
    stroke-dasharray: 26;
    stroke-dashoffset: 26px;
  }

  .m8 .branch-medium, .tree-1 .branch-medium, .tree-2 .branch-medium, .tree-3 .branch-medium, .tree-4 .branch-medium, .tree-5 .branch-medium {
    stroke-dasharray: 220;
    stroke-dashoffset: 220px;
  }

  .m8 .animate .stem, .tree-1 .animate .stem, .tree-2 .animate .stem, .tree-3 .animate .stem, .tree-4 .animate .stem, .tree-5 .animate .stem {
    animation: 4s linear forwards dash;
  }

  .m8 .animate .branch-small, .tree-1 .animate .branch-small, .tree-2 .animate .branch-small, .tree-3 .animate .branch-small, .tree-4 .animate .branch-small, .tree-5 .animate .branch-small {
    animation: .8s cubic-bezier(0, 0, .57, 1.02) forwards dash;
  }

  .m8 .animate .branch-medium, .tree-1 .animate .branch-medium, .tree-2 .animate .branch-medium, .tree-3 .animate .branch-medium, .tree-4 .animate .branch-medium, .tree-5 .animate .branch-medium {
    animation: 1s cubic-bezier(0, 0, .57, 1.02) forwards dash;
  }

  .m8 .animate .branch-01-right, .tree-1 .animate .branch-01-right, .tree-2 .animate .branch-01-right, .tree-3 .animate .branch-01-right, .tree-4 .animate .branch-01-right, .tree-5 .animate .branch-01-right {
    animation-delay: 95.2381ms;
  }

  .m8 .animate .leaf-01-right, .tree-1 .animate .leaf-01-right, .tree-2 .animate .leaf-01-right, .tree-3 .animate .leaf-01-right, .tree-4 .animate .leaf-01-right, .tree-5 .animate .leaf-01-right {
    animation: .5s .133333s forwards leafbigger;
  }

  .m8 .animate .branch-01-left, .tree-1 .animate .branch-01-left, .tree-2 .animate .branch-01-left, .tree-3 .animate .branch-01-left, .tree-4 .animate .branch-01-left, .tree-5 .animate .branch-01-left {
    animation-delay: .235294s;
  }

  .m8 .animate .leaf-01-left, .tree-1 .animate .leaf-01-left, .tree-2 .animate .leaf-01-left, .tree-3 .animate .leaf-01-left, .tree-4 .animate .leaf-01-left, .tree-5 .animate .leaf-01-left {
    animation: .5s .333333s forwards leafbigger;
  }

  .m8 .animate .branch-02-right, .tree-1 .animate .branch-02-right, .tree-2 .animate .branch-02-right, .tree-3 .animate .branch-02-right, .tree-4 .animate .branch-02-right, .tree-5 .animate .branch-02-right {
    animation-delay: .5s;
  }

  .m8 .animate .leaf-02-right, .tree-1 .animate .leaf-02-right, .tree-2 .animate .leaf-02-right, .tree-3 .animate .leaf-02-right, .tree-4 .animate .leaf-02-right, .tree-5 .animate .leaf-02-right {
    animation: .7s .666667s forwards leafbigger;
  }

  .m8 .animate .branch-02-left, .tree-1 .animate .branch-02-left, .tree-2 .animate .branch-02-left, .tree-3 .animate .branch-02-left, .tree-4 .animate .branch-02-left, .tree-5 .animate .branch-02-left {
    animation-delay: .784314s;
  }

  .m8 .animate .leaf-02-left, .tree-1 .animate .leaf-02-left, .tree-2 .animate .leaf-02-left, .tree-3 .animate .leaf-02-left, .tree-4 .animate .leaf-02-left, .tree-5 .animate .leaf-02-left {
    animation: .7s .851064s forwards leafbigger;
  }

  .m8 .animate .branch-03-right, .tree-1 .animate .branch-03-right, .tree-2 .animate .branch-03-right, .tree-3 .animate .branch-03-right, .tree-4 .animate .branch-03-right, .tree-5 .animate .branch-03-right {
    animation-delay: 1s;
  }

  .m8 .animate .leaf-03-right, .tree-1 .animate .leaf-03-right, .tree-2 .animate .leaf-03-right, .tree-3 .animate .leaf-03-right, .tree-4 .animate .leaf-03-right, .tree-5 .animate .leaf-03-right {
    animation: .5s 1.11111s forwards leafbigger;
  }

  .m8 .animate .branch-04-right, .tree-1 .animate .branch-04-right, .tree-2 .animate .branch-04-right, .tree-3 .animate .branch-04-right, .tree-4 .animate .branch-04-right, .tree-5 .animate .branch-04-right {
    animation-delay: 1.37931s;
  }

  .m8 .animate .leaf-04-right, .tree-1 .animate .leaf-04-right, .tree-2 .animate .leaf-04-right, .tree-3 .animate .leaf-04-right, .tree-4 .animate .leaf-04-right, .tree-5 .animate .leaf-04-right {
    animation: .5s 1.6s forwards leafbigger;
  }

  .m8 .animate .branch-03-left, .tree-1 .animate .branch-03-left, .tree-2 .animate .branch-03-left, .tree-3 .animate .branch-03-left, .tree-4 .animate .branch-03-left, .tree-5 .animate .branch-03-left {
    animation-delay: 1.66667s;
  }

  .m8 .animate .leaf-03-left, .tree-1 .animate .leaf-03-left, .tree-2 .animate .leaf-03-left, .tree-3 .animate .leaf-03-left, .tree-4 .animate .leaf-03-left, .tree-5 .animate .leaf-03-left {
    animation: .7s 1.73913s forwards leafbigger;
  }

  .m8 .animate .branch-05-right, .tree-1 .animate .branch-05-right, .tree-2 .animate .branch-05-right, .tree-3 .animate .branch-05-right, .tree-4 .animate .branch-05-right, .tree-5 .animate .branch-05-right {
    animation-delay: 2s;
  }

  .m8 .animate .leaf-05-right, .tree-1 .animate .leaf-05-right, .tree-2 .animate .leaf-05-right, .tree-3 .animate .leaf-05-right, .tree-4 .animate .leaf-05-right, .tree-5 .animate .leaf-05-right {
    animation: .7s 2s forwards leafbigger;
  }

  .m8 .animate .branch-04-left, .tree-1 .animate .branch-04-left, .tree-2 .animate .branch-04-left, .tree-3 .animate .branch-04-left, .tree-4 .animate .branch-04-left, .tree-5 .animate .branch-04-left {
    animation-delay: 2.32558s;
  }

  .m8 .animate .leaf-04-left, .tree-1 .animate .leaf-04-left, .tree-2 .animate .leaf-04-left, .tree-3 .animate .leaf-04-left, .tree-4 .animate .leaf-04-left, .tree-5 .animate .leaf-04-left {
    animation: .5s 2.32558s forwards leafbigger;
  }

  .m8 .animate .branch-06-right, .tree-1 .animate .branch-06-right, .tree-2 .animate .branch-06-right, .tree-3 .animate .branch-06-right, .tree-4 .animate .branch-06-right, .tree-5 .animate .branch-06-right {
    animation-delay: 2.8169s;
  }

  .m8 .animate .leaf-06-right, .tree-1 .animate .leaf-06-right, .tree-2 .animate .leaf-06-right, .tree-3 .animate .leaf-06-right, .tree-4 .animate .leaf-06-right, .tree-5 .animate .leaf-06-right {
    animation: .5s 2.85714s forwards leafbigger;
  }

  .m8 .animate .branch-05-left, .tree-1 .animate .branch-05-left, .tree-2 .animate .branch-05-left, .tree-3 .animate .branch-05-left, .tree-4 .animate .branch-05-left, .tree-5 .animate .branch-05-left {
    animation-delay: 3.47826s;
  }

  .m8 .animate .leaf-05-left, .tree-1 .animate .leaf-05-left, .tree-2 .animate .leaf-05-left, .tree-3 .animate .leaf-05-left, .tree-4 .animate .leaf-05-left, .tree-5 .animate .leaf-05-left {
    animation: .5s 3.47826s forwards leafbigger;
  }

  .m8 .animate .leaf-final-mid, .tree-1 .animate .leaf-final-mid, .tree-2 .animate .leaf-final-mid, .tree-3 .animate .leaf-final-mid, .tree-4 .animate .leaf-final-mid, .tree-5 .animate .leaf-final-mid {
    animation: .3s 3.77358s forwards leafbigger;
  }

  .m8 .animate .leaf, .tree-1 .animate .leaf, .tree-2 .animate .leaf, .tree-3 .animate .leaf, .tree-4 .animate .leaf, .tree-5 .animate .leaf {
    animation-timing-function: cubic-bezier(.24, .95, .89, 1.18);
  }

  .m8 .animate .stars, .tree-1 .animate .stars, .tree-2 .animate .stars, .tree-3 .animate .stars, .tree-4 .animate .stars, .tree-5 .animate .stars {
    animation: .3s cubic-bezier(.24, .95, .89, 1.18) 4s forwards opacityAppear;
  }

  .m8 .animate .star-01, .tree-1 .animate .star-01, .tree-2 .animate .star-01, .tree-3 .animate .star-01, .tree-4 .animate .star-01, .tree-5 .animate .star-01 {
    animation: 3s 4.7s infinite pulse;
  }

  .m8 .animate .star-02, .tree-1 .animate .star-02, .tree-2 .animate .star-02, .tree-3 .animate .star-02, .tree-4 .animate .star-02, .tree-5 .animate .star-02 {
    animation: 3s 5.2s infinite pulse;
  }

  .m8 .animate .star-03, .tree-1 .animate .star-03, .tree-2 .animate .star-03, .tree-3 .animate .star-03, .tree-4 .animate .star-03, .tree-5 .animate .star-03 {
    animation: 3s 5.5s infinite pulse;
  }

  .m8 .animate .sparkles .line, .tree-1 .animate .sparkles .line, .tree-2 .animate .sparkles .line, .tree-3 .animate .sparkles .line, .tree-4 .animate .sparkles .line, .tree-5 .animate .sparkles .line {
    animation: .4s ease-in-out 4s dashSparkle;
  }

  .m8 .stars, .tree-1 .stars, .tree-2 .stars, .tree-3 .stars, .tree-4 .stars, .tree-5 .stars {
    opacity: 0;
  }

  .m8 .leaf, .tree-1 .leaf, .tree-2 .leaf, .tree-3 .leaf, .tree-4 .leaf, .tree-5 .leaf {
    opacity: .8;
  }

  .m8 .leaf-01-right, .tree-1 .leaf-01-right, .tree-2 .leaf-01-right, .tree-3 .leaf-01-right, .tree-4 .leaf-01-right, .tree-5 .leaf-01-right {
    transform-origin: 45.79px 420.83px;
    transform: translate(-7px, 7px)scale(0);
  }

  .m8 .leaf-01-left, .tree-1 .leaf-01-left, .tree-2 .leaf-01-left, .tree-3 .leaf-01-left, .tree-4 .leaf-01-left, .tree-5 .leaf-01-left {
    transform-origin: 29.75px 399.8px;
    transform: translate(7px, 7px)scale(0);
  }

  .m8 .leaf-02-right, .tree-1 .leaf-02-right, .tree-2 .leaf-02-right, .tree-3 .leaf-02-right, .tree-4 .leaf-02-right, .tree-5 .leaf-02-right {
    transform-origin: 53px 364.68px;
    transform: translate(-10px, 10px)scale(0);
  }

  .m8 .leaf-02-left, .tree-1 .leaf-02-left, .tree-2 .leaf-02-left, .tree-3 .leaf-02-left, .tree-4 .leaf-02-left, .tree-5 .leaf-02-left {
    transform-origin: 28.22px 341.98px;
    transform: translate(7px, 7px)scale(0);
  }

  .m8 .leaf-03-right, .tree-1 .leaf-03-right, .tree-2 .leaf-03-right, .tree-3 .leaf-03-right, .tree-4 .leaf-03-right, .tree-5 .leaf-03-right {
    transform-origin: 45.58px 304.49px;
    transform: translate(-7px, 7px)scale(0);
  }

  .m8 .leaf-04-right, .tree-1 .leaf-04-right, .tree-2 .leaf-04-right, .tree-3 .leaf-04-right, .tree-4 .leaf-04-right, .tree-5 .leaf-04-right {
    transform-origin: 49.1px 250.92px;
    transform: translate(-3px, 3px)scale(0);
  }

  .m8 .leaf-03-left, .tree-1 .leaf-03-left, .tree-2 .leaf-03-left, .tree-3 .leaf-03-left, .tree-4 .leaf-03-left, .tree-5 .leaf-03-left {
    transform-origin: 30.13px 217.64px;
    transform: translate(10px, 7px)scale(0);
  }

  .m8 .leaf-05-right, .tree-1 .leaf-05-right, .tree-2 .leaf-05-right, .tree-3 .leaf-05-right, .tree-4 .leaf-05-right, .tree-5 .leaf-05-right {
    transform-origin: 48.51px 173.52px;
    transform: translate(-10px, 10px)scale(0);
  }

  .m8 .leaf-04-left, .tree-1 .leaf-04-left, .tree-2 .leaf-04-left, .tree-3 .leaf-04-left, .tree-4 .leaf-04-left, .tree-5 .leaf-04-left {
    transform-origin: 33.35px 139.12px;
    transform: translate(7px, 7px)scale(0);
  }

  .m8 .leaf-06-right, .tree-1 .leaf-06-right, .tree-2 .leaf-06-right, .tree-3 .leaf-06-right, .tree-4 .leaf-06-right, .tree-5 .leaf-06-right {
    transform-origin: 58.97px 78.99px;
    transform: translate(-3px, 3px)scale(0);
  }

  .m8 .leaf-05-left, .tree-1 .leaf-05-left, .tree-2 .leaf-05-left, .tree-3 .leaf-05-left, .tree-4 .leaf-05-left, .tree-5 .leaf-05-left {
    transform-origin: 33.58px 68.03px;
    transform: translate(7px, 7px)scale(0);
  }

  .m8 .leaf-final-mid, .tree-1 .leaf-final-mid, .tree-2 .leaf-final-mid, .tree-3 .leaf-final-mid, .tree-4 .leaf-final-mid, .tree-5 .leaf-final-mid {
    transform-origin: 39.17px 32.83px;
    transform: translate(0, 5px)scale(0);
  }

  .m8 .star-01, .tree-1 .star-01, .tree-2 .star-01, .tree-3 .star-01, .tree-4 .star-01, .tree-5 .star-01 {
    transform-origin: 54.26px 7.95px;
  }

  .m8 .star-02, .tree-1 .star-02, .tree-2 .star-02, .tree-3 .star-02, .tree-4 .star-02, .tree-5 .star-02 {
    transform-origin: 12.9px 16.6px;
  }

  .m8 .star-03, .tree-1 .star-03, .tree-2 .star-03, .tree-3 .star-03, .tree-4 .star-03, .tree-5 .star-03 {
    transform-origin: 63.06px 36.95px;
  }

  .m8 .sparkles .line, .tree-1 .sparkles .line, .tree-2 .sparkles .line, .tree-3 .sparkles .line, .tree-4 .sparkles .line, .tree-5 .sparkles .line {
    stroke-dasharray: 10;
    stroke-dashoffset: 10px;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dashSparkle {
    to {
      stroke-dashoffset: -7px;
    }
  }

  @keyframes opacityAppear {
    to {
      opacity: 1;
    }
  }

  @keyframes leafbigger {
    to {
      opacity: 1;
      transform: translate(0)scale(1);
    }
  }
}

.m8 .primary-filled, .tree-1 .primary-filled, .tree-2 .primary-filled, .tree-3 .primary-filled, .tree-4 .primary-filled, .tree-5 .primary-filled {
  fill: #fd0095;
}

.m8 .primary-border, .tree-1 .primary-border, .tree-2 .primary-border, .tree-3 .primary-border, .tree-4 .primary-border, .tree-5 .primary-border {
  fill: none;
  stroke: #fd0095;
  stroke-width: 2.44px;
  stroke-linecap: round;
}

.m8 .stem, .tree-1 .stem, .tree-2 .stem, .tree-3 .stem, .tree-4 .stem, .tree-5 .stem {
  stroke-width: 2.44px;
  fill: none;
  stroke: #fd0095;
  stroke-linecap: round;
}

.m8 .secondary-border, .tree-1 .secondary-border, .tree-2 .secondary-border, .tree-3 .secondary-border, .tree-4 .secondary-border, .tree-5 .secondary-border {
  fill: #fd0095;
}

.m8 .branch, .m8 .heart-border, .tree-1 .branch, .tree-1 .heart-border, .tree-2 .branch, .tree-2 .heart-border, .tree-3 .branch, .tree-3 .heart-border, .tree-4 .branch, .tree-4 .heart-border, .tree-5 .branch, .tree-5 .heart-border {
  fill: none;
  stroke-linecap: round;
}

.m8 .branch, .tree-1 .branch, .tree-2 .branch, .tree-3 .branch, .tree-4 .branch, .tree-5 .branch {
  stroke: #fd0095;
  stroke-width: 2.44px;
}

.m8 .pot-bg, .tree-1 .pot-bg, .tree-2 .pot-bg, .tree-3 .pot-bg, .tree-4 .pot-bg, .tree-5 .pot-bg {
  fill: #fd0095;
}

.m8 .heart-bg, .tree-1 .heart-bg, .tree-2 .heart-bg, .tree-3 .heart-bg, .tree-4 .heart-bg, .tree-5 .heart-bg {
  fill: #fff;
}

.m8 .pot-border, .tree-1 .pot-border, .tree-2 .pot-border, .tree-3 .pot-border, .tree-4 .pot-border, .tree-5 .pot-border {
  fill: #fd0095;
}

.m8 .heart-border, .tree-1 .heart-border, .tree-2 .heart-border, .tree-3 .heart-border, .tree-4 .heart-border, .tree-5 .heart-border {
  stroke: #fd0095;
  stroke-linejoin: round;
  stroke-width: 2.9px;
}

.m8 svg, .tree-1 svg, .tree-2 svg, .tree-3 svg, .tree-4 svg, .tree-5 svg {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.m8 .container, .tree-1 .container, .tree-2 .container, .tree-3 .container, .tree-4 .container, .tree-5 .container {
  width: 100%;
}

.m8 .theplant, .tree-1 .theplant, .tree-2 .theplant, .tree-3 .theplant, .tree-4 .theplant, .tree-5 .theplant {
  max-width: 350px;
  margin-top: auto;
  margin-bottom: auto;
}

.m8 .details, .tree-1 .details, .tree-2 .details, .tree-3 .details, .tree-4 .details, .tree-5 .details {
  color: #fd0095;
  text-align: center;
  background: #fd0095;
  align-self: stretch;
  padding: 1em;
}

.m8 .button, .tree-1 .button, .tree-2 .button, .tree-3 .button, .tree-4 .button, .tree-5 .button {
  color: #fd0095;
  letter-spacing: -.005em;
  z-index: 1;
  cursor: pointer;
  background-color: #0000;
  background-image: linear-gradient(to right, #fd0095, #fd0095);
  background-position: 400px 210px;
  background-repeat: no-repeat;
  background-size: calc(100% - 5px) calc(100% - 5px);
  border: 0;
  min-width: 150px;
  max-width: 100%;
  margin: 3em .33em .75em;
  padding: .4em 1.3em;
  font-family: Raleway, sans-serif;
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.m8 .button span:before, .tree-1 .button span:before, .tree-2 .button span:before, .tree-3 .button span:before, .tree-4 .button span:before, .tree-5 .button span:before {
  content: "";
  border: 2px solid #fd0095;
  border-radius: 3px;
  position: absolute;
  inset: -2px;
}

.m8 .button:before, .m8 .button:after, .tree-1 .button:before, .tree-1 .button:after, .tree-2 .button:before, .tree-2 .button:after, .tree-3 .button:before, .tree-3 .button:after, .tree-4 .button:before, .tree-4 .button:after, .tree-5 .button:before, .tree-5 .button:after {
  content: "";
  z-index: -1;
  background: #fd0095;
  position: absolute;
  bottom: -6px;
  right: -6px;
}

.m8 .button:before, .tree-1 .button:before, .tree-2 .button:before, .tree-3 .button:before, .tree-4 .button:before, .tree-5 .button:before {
  height: 8px;
  left: 5px;
}

.m8 .button:after, .tree-1 .button:after, .tree-2 .button:after, .tree-3 .button:after, .tree-4 .button:after, .tree-5 .button:after {
  width: 8px;
  top: 5px;
}

.m8 .button.inverted, .tree-1 .button.inverted, .tree-2 .button.inverted, .tree-3 .button.inverted, .tree-4 .button.inverted, .tree-5 .button.inverted {
  background-image: linear-gradient(to right, #fd0095, #fd0095);
}

.m8 .button.inverted:before, .m8 .button.inverted:after, .tree-1 .button.inverted:before, .tree-1 .button.inverted:after, .tree-2 .button.inverted:before, .tree-2 .button.inverted:after, .tree-3 .button.inverted:before, .tree-3 .button.inverted:after, .tree-4 .button.inverted:before, .tree-4 .button.inverted:after, .tree-5 .button.inverted:before, .tree-5 .button.inverted:after {
  background: #fd0095;
}

.m8 .disabled, .tree-1 .disabled, .tree-2 .disabled, .tree-3 .disabled, .tree-4 .disabled, .tree-5 .disabled {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: all !important;
}

.m8 .button:hover, .m8 .button:focus, .tree-1 .button:hover, .tree-1 .button:focus, .tree-2 .button:hover, .tree-2 .button:focus, .tree-3 .button:hover, .tree-3 .button:focus, .tree-4 .button:hover, .tree-4 .button:focus, .tree-5 .button:hover, .tree-5 .button:focus {
  background-color: #0000;
  background-position: 100% 100%;
  outline: 0;
}

.m8 .button:active, .tree-1 .button:active, .tree-2 .button:active, .tree-3 .button:active, .tree-4 .button:active, .tree-5 .button:active {
  transform: scale(.9);
}

.m8 .button span > *, .tree-1 .button span > *, .tree-2 .button span > *, .tree-3 .button span > *, .tree-4 .button span > *, .tree-5 .button span > * {
  vertical-align: center;
  display: inline-block;
}

.m8 .button.is-light, .tree-1 .button.is-light, .tree-2 .button.is-light, .tree-3 .button.is-light, .tree-4 .button.is-light, .tree-5 .button.is-light {
  border-bottom: 0;
  min-width: auto;
  padding: 0;
}

.m8 .button.is-light:before, .m8 .button.is-light:after, .tree-1 .button.is-light:before, .tree-1 .button.is-light:after, .tree-2 .button.is-light:before, .tree-2 .button.is-light:after, .tree-3 .button.is-light:before, .tree-3 .button.is-light:after, .tree-4 .button.is-light:before, .tree-4 .button.is-light:after, .tree-5 .button.is-light:before, .tree-5 .button.is-light:after {
  display: none;
}

.m8 .button.is-light:hover, .m8 .button.is-light:focus, .tree-1 .button.is-light:hover, .tree-1 .button.is-light:focus, .tree-2 .button.is-light:hover, .tree-2 .button.is-light:focus, .tree-3 .button.is-light:hover, .tree-3 .button.is-light:focus, .tree-4 .button.is-light:hover, .tree-4 .button.is-light:focus, .tree-5 .button.is-light:hover, .tree-5 .button.is-light:focus {
  background-position: 7px 18px;
}

.m8 .button, .m8 input, .m8 select, .m8 textarea, .tree-1 .button, .tree-1 input, .tree-1 select, .tree-1 textarea, .tree-2 .button, .tree-2 input, .tree-2 select, .tree-2 textarea, .tree-3 .button, .tree-3 input, .tree-3 select, .tree-3 textarea, .tree-4 .button, .tree-4 input, .tree-4 select, .tree-4 textarea, .tree-5 .button, .tree-5 input, .tree-5 select, .tree-5 textarea {
  text-decoration: none;
  transition: all .275s;
}

.step-4 .latter-p {
  position: relative;
  bottom: 93px;
  left: 95px;
}

.step-4 .latter-r {
  position: relative;
  bottom: 93px;
  left: 80px;
}

.step-4 .latter-o {
  position: relative;
  bottom: 93px;
  left: 54px;
}

.step-4 .latter-v {
  position: relative;
  bottom: 93px;
  left: 61px;
}

.step-4 .latter-e {
  position: relative;
  bottom: 93px;
  left: 65px;
}

.card-scroll-chp-2 {
  overflow: hidden;
}

.card-scroll-chp-2 p {
  width: 210px;
}

.overlay {
  z-index: 9998;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay .cookie-popup {
  background: #2b373b;
  flex-direction: column;
  gap: 25px;
  max-width: 500px;
  padding: 30px;
  display: flex;
}

.overlay .cookie-popup p {
  text-align: center;
  color: #fff;
}

.overlay .cookie-popup p a {
  color: #fff;
}

.overlay .cookie-popup .btn-wrap {
  justify-content: center;
  gap: 20px;
  display: flex;
}

.overlay .cookie-popup .btn-wrap .btn {
  color: #fff;
  background: #fd0095;
  border: none;
  padding: 5px 10px;
}

.overlay .cookie-popup .btn-wrap .ebtn {
  background-color: #0000;
}

.m1 {
  position: relative;
}

.m1 .left-side {
  position: absolute;
}

@media (width <= 1299px) {
  .m1 .left-side {
    left: -70px;
  }
}

@media (width <= 1199px) {
  .m1 .left-side {
    left: -150px;
  }
}

@media (width <= 991px) {
  .m1 .left-side {
    display: none;
  }
}

.m1 .left-side figure {
  z-index: 2;
  position: relative;
}

.m1 .left-side figure img {
  max-width: 400px;
  height: 558px;
}

.m1 .left-side svg {
  position: absolute;
  top: -18px;
}

.m1 .m1-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 206px;
  height: 740px;
  display: flex;
}

@media (width <= 991px) {
  .m1 .m1-container {
    gap: 0;
    height: auto;
  }
}

.m1 .m1-container .row .col12 article {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width <= 575px) {
  .m1 .m1-container .row .col12 article {
    max-width: 280px;
  }
}

.m1 .m1-container .row .col12 article h1 {
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (width <= 991px) {
  .m1 .m1-container .row .col12 article h1 {
    font-size: 39px;
  }
}

.m1 .m1-container .row .col12 article h2 {
  color: #000;
  text-align: center;
  max-width: 606px;
  font-family: Manrope;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%;
}

@media (width <= 991px) {
  .m1 .m1-container .row .col12 article h2 {
    padding-top: 10px;
    font-size: 20px;
  }
}

@media (width <= 575px) {
  .m1 .m1-container .row .col12 article h2 {
    max-width: 240px;
  }
}

.m1 .m1-container .row .col12 article p {
  color: var(--Hotwire-Brand-Black-RGB, #000);
  text-align: center;
  padding-top: 34px;
  font-family: Manrope;
  font-size: 21.529px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (width <= 991px) {
  .m1 .m1-container .row .col12 article p {
    padding-top: 20px;
    font-size: 16px;
  }
}

@media (width <= 575px) {
  .m1 .m1-container .row .col12 article p {
    max-width: 230px;
  }
}

.m1 .m1-container .box-wrap {
  justify-content: center;
  gap: 8px;
  display: flex;
}

@media (width <= 1199px) {
  .m1 .m1-container .box-wrap {
    gap: 15px;
  }
}

@media (width <= 991px) {
  .m1 .m1-container .box-wrap {
    display: none;
  }
}

.m1 .m1-container .box-wrap a {
  color: #fd0095;
  background: #f6e4ec;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 465px;
  height: 72px;
  padding: 10px 30px 8px;
  font-family: Manrope;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  transition: all .25s ease-in-out;
  display: flex;
}

@media (width <= 1199px) {
  .m1 .m1-container .box-wrap a {
    padding: 10px 15px 8px;
    font-size: 19px;
  }
}

.m1 .m1-container .box-wrap a:hover {
  color: #fff;
  background: #fd0095;
}

.m1 .m1-container .box-wrap a:hover svg path {
  fill: #fff;
}

.m1 .m1-container .box-wrap a:hover svg path:nth-child(2) {
  fill: #fd0095;
}

.m1 .m1-container .box-wrap a svg fill {
  transition: all .25s ease-in-out;
}

@media (width <= 767px) {
  .m1 .m1-container .box-wrap {
    flex-direction: column;
  }
}

.m1 .right-side {
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 1299px) {
  .m1 .right-side {
    right: -70px;
  }
}

@media (width <= 1199px) {
  .m1 .right-side {
    right: -150px;
  }
}

@media (width <= 991px) {
  .m1 .right-side {
    display: none;
  }
}

.m1 .right-side figure {
  z-index: 2;
  position: relative;
}

@media (width <= 767px) {
  .m1 .right-side figure {
    padding-left: 30px;
  }
}

.m1 .right-side figure img {
  max-width: 466px;
  height: 496px;
}

@media (width <= 767px) {
  .m1 .right-side figure img {
    max-width: 100%;
    height: auto;
  }
}

.m1 .right-side svg {
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 767px) {
  .m1 .right-side svg {
    max-width: 327px;
    height: 665px;
  }
}

@media (width <= 991px) {
  .m1 .m1-container {
    padding-bottom: 0;
  }
}

@media (width <= 575px) {
  .m1 .m1-container {
    padding-bottom: 0;
  }
}

.m1 .main-img {
  text-align: right;
  width: 100%;
  display: none;
}

.m1 .main-img img {
  max-width: 80%;
  display: inline-block;
}

@media (width <= 991px) {
  .m1 .main-img {
    display: inline-block;
  }
}

.m1 .tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 12px no-repeat;
  margin: 0 0 10px;
  padding: 2px 0 2px 20px;
  font-size: 13px;
  font-weight: 400;
}

.m1 .btm-selection {
  padding: 20px;
  display: none;
}

@media (width <= 991px) {
  .m1 .btm-selection {
    display: block;
  }
}

.m1 .btm-selection ul {
  display: flex;
}

.m1 .btm-selection ul li {
  flex: 0 0 33.3333%;
  list-style: none;
}

.m1 .btm-selection ul li a {
  color: #fd0095;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  outline: 0 !important;
}

.m1 .btm-selection ul li a:hover {
  border-bottom: 2px solid #fd0095;
}

.m2 {
  background-color: #fd0095;
  position: relative;
}

.m2 .power-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.m2 .power-container .power-row {
  align-items: center;
}

.m2 .power-container .power-row .col6 article p {
  color: #fff;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (width <= 767px) {
  .m2 .power-container .power-row .col6 article p {
    font-size: 16px;
  }
}

@media (width <= 991px) {
  .m2 .power-container .power-row .right-side-col {
    display: none;
  }
}

@media (width <= 767px) {
  .m2 .power-container .power-row .right-side-col {
    padding-top: 93px;
  }
}

.m2 .power-container .power-row .right-side-col article figure {
  margin-top: calc(18% - 25px);
  margin-left: calc(21% - 25px);
}

.m2 .power-container .power-row .right-side-col article figure img {
  max-width: 547px;
  height: 731px;
}

@media (width <= 767px) {
  .m2 .power-container .power-row .right-side-col article figure img {
    max-width: 100%;
    height: auto;
  }
}

.m2 .power-container .main-img {
  display: none;
}

@media (width <= 991px) {
  .m2 .power-container .main-img {
    max-width: 350px;
    margin: 40px 0 0 -15px;
    display: block;
  }
}

@media (width <= 575px) {
  .m2 .power-container .main-img {
    max-width: 270px;
  }
}

.m2 .stroke {
  position: absolute;
  right: 0;
}

@media (width <= 1299px) {
  .m2 .stroke {
    right: -70px;
  }
}

@media (width <= 1199px) {
  .m2 .stroke {
    right: -150px;
  }
}

@media (width <= 991px) {
  .m2 .stroke {
    display: none;
  }
}

@media (width <= 767px) {
  .m2 .stroke {
    max-width: 820px;
    height: 682px;
    bottom: -23px;
    left: -551px;
    transform: scaleX(-1);
  }
}

.m3 {
  position: relative;
}

.m3 .left-side-svg {
  position: absolute;
}

.m3 .left-side-svg .svg-top {
  height: 430px;
  display: block;
  position: relative;
  top: -40px;
  left: -100px;
}

@media (width <= 991px) {
  .m3 .left-side-svg {
    display: none;
  }
}

.m3 .left-side-svg .hand-svg {
  transition: all 2s ease-in-out;
  position: relative;
}

.m3 .left-side-svg .hand-svg .inner-svg {
  position: absolute;
  top: calc(10vh - 194px);
  left: calc(10vw + 90px);
}

.m3 .left-side-svg .hand-svg-card {
  margin-top: -100px;
  transition: display 2s ease-in-out;
  display: none;
  position: relative;
  top: -150px;
  left: -60px;
}

.m3 .left-side-svg .hand-svg-card img {
  max-width: 400px;
}

.m3 .container {
  padding: 100px 0;
}

@media (width <= 991px) {
  .m3 .container {
    padding: 50px 0 0;
  }
}

.m3 .container .row .playing-cards {
  flex-direction: column;
  gap: 50px;
  display: flex;
}

.m3 .container .row .playing-cards h3 {
  color: #000;
  text-align: left;
  max-width: 680px;
  margin-left: calc(10% + 20px);
  font-family: Manrope;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (width <= 991px) {
  .m3 .container .row .playing-cards h3 {
    margin: 0 0 0 20px;
  }
}

@media (width <= 767px) {
  .m3 .container .row .playing-cards h3 {
    font-size: 25px;
  }
}

.m3 .container .row .playing-cards .cards-wrap {
  cursor: pointer;
  padding: 40px 0 20px;
  display: flex;
  position: relative;
  top: 50px;
  left: 53px;
}

@media (width <= 991px) {
  .m3 .container .row .playing-cards .cards-wrap {
    display: none;
  }
}

@media (width <= 767px) {
  .m3 .container .row .playing-cards .cards-wrap {
    display: none;
  }
}

.m3 .container .row .playing-cards .cards-wrap .playing-card-uno {
  width: 218px;
  height: 294px;
  transition: all .5s ease-in-out;
}

.m3 .container .row .playing-cards .cards-wrap .playing-card-uno h2 {
  color: #fff;
  padding-top: calc(30% - 50px);
  font-family: Manrope;
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.m3 .container .row .playing-cards .cards-wrap .playing-card-uno p {
  color: #fff;
  word-break: break-word;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-1 {
  background: #62165c;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  transform: rotate(-30deg)scale(1);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-1:hover {
  z-index: 2;
  transform: rotate(-30deg)scale(1.2);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-2 {
  background: #4a1f69;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  position: absolute;
  top: -19px;
  left: 112px;
  transform: rotate(-17.489deg)scale(1);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-2:hover {
  z-index: 2;
  transform: rotate(-17.489deg)scale(1.2);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-3 {
  background: #1c3075;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  position: absolute;
  top: -44px;
  left: 219px;
  transform: rotate(4.243deg)scale(1);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-3:hover {
  z-index: 2;
  transform: rotate(4.243deg)scale(1.2);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-4 {
  background: #b50057;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  position: absolute;
  top: -32px;
  right: 231px;
  transform: rotate(19.227deg);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-4:hover {
  z-index: 2;
  transform: rotate(19.227deg)scale(1.2);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-5 {
  background: #fd0095;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 20px;
  right: 118px;
  transform: rotate(38.208deg);
}

.m3 .container .row .playing-cards .cards-wrap .card-wrap-5:hover {
  z-index: 2;
  transform: rotate(38.208deg)scale(1.2);
}

.m3 .container .row .playing-cards .bottom-text {
  padding-top: 27px;
}

@media (width <= 991px) {
  .m3 .container .row .playing-cards .bottom-text {
    display: none;
  }
}

.m3 .container .row .playing-cards .bottom-text p {
  text-align: right;
}

.m3 .card-wrap-1:hover > .hand-svg-1 {
  display: block;
}

.mobile-playing-cards, .mobile-playing-cards .bottom-text {
  display: none;
}

@media (width <= 991px) {
  .mobile-playing-cards .bottom-text {
    padding: 20px 0 0 20px;
    display: block;
  }
}

.mobile-playing-cards .slick-slide {
  padding: 0 20px;
}

@media (width <= 991px) {
  .mobile-playing-cards {
    display: block;
  }
}

.mobile-playing-cards .playing-cards-slider {
  gap: 18px;
  padding: 36px 0 0;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .playing-card-uno {
  max-width: 100%;
  height: 215px;
}

.mobile-playing-cards .playing-cards-slider .playing-card-uno h2 {
  color: #fff;
  padding-top: 0;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.mobile-playing-cards .playing-cards-slider .playing-card-uno p {
  color: #fff;
  word-break: break-word;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-1 {
  background: #62165c;
  border-radius: 24px;
  flex-direction: column;
  gap: 15px;
  padding: 18px;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-1:hover {
  z-index: 2;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-2 {
  background: #4a1f69;
  border-radius: 24px;
  flex-direction: column;
  gap: 15px;
  padding: 18px;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-2:hover {
  z-index: 2;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-3 {
  background: #1c3075;
  border-radius: 24px;
  flex-direction: column;
  gap: 15px;
  padding: 18px;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-3:hover {
  z-index: 2;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-4 {
  background: #b50057;
  border-radius: 24px;
  flex-direction: column;
  gap: 15px;
  padding: 18px;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-4:hover {
  z-index: 2;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-5 {
  background: #fd0095;
  border-radius: 24px;
  flex-direction: column;
  gap: 15px;
  padding: 18px;
  display: flex;
}

.mobile-playing-cards .playing-cards-slider .card-wrap-5:hover {
  z-index: 2;
}

.m4 {
  padding: 0 0 100px;
  position: relative;
  overflow: hidden;
}

@media (width <= 991px) {
  .m4 {
    padding: 100px 0 50px;
  }
}

@media (width <= 767px) {
  .m4 {
    flex-direction: column;
    gap: 50px;
    padding: 40px 0 100px;
    display: flex;
  }
}

.m4 .min-height {
  min-height: 500px;
}

@media (width <= 991px) {
  .m4 .min-height {
    min-height: 380px;
    display: block;
  }
}

@media (width <= 767px) {
  .m4 .min-height {
    min-height: 410px;
  }
}

@media (width <= 575px) {
  .m4 .min-height {
    min-height: 530px;
  }
}

.m4 .analyse-container .aaa-row {
  gap: 30px;
  margin: 0;
}

@media (width <= 767px) {
  .m4 .analyse-container .aaa-row {
    flex-direction: column;
    gap: 18px;
  }
}

.m4 .analyse-container .aaa-row .col5 article {
  max-width: 468px;
}

@media (width <= 991px) {
  .m4 .analyse-container .aaa-row .col5 article {
    max-width: 100%;
    padding-bottom: 40px;
  }
}

.m4 .analyse-container .aaa-row .col5 article h2 .analyse-article {
  color: #000;
  cursor: pointer;
  font-family: Manrope;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: block;
}

.m4 .analyse-container .aaa-row .col5 article h2 .analyse-article:hover {
  color: #fd0095 !important;
}

.m4 .analyse-container .aaa-row .col5 article h2 .analyse-article.active {
  color: #fd0095;
  font-family: Manrope;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (width <= 991px) {
  .m4 .analyse-container .aaa-row .col5 article h2 .analyse-article {
    font-size: 49px !important;
  }
}

.m4 .analyse-container .aaa-row .analyse-article-details {
  display: none;
}

@media (width <= 991px) {
  .m4 .analyse-container .aaa-row .analyse-article-details {
    flex: 100%;
  }
}

.m4 .analyse-container .aaa-row .analyse-article-details.active {
  display: block;
}

.m4 .analyse-container .aaa-row .analyse-article-details article {
  max-width: 560px;
}

@media (width <= 991px) {
  .m4 .analyse-container .aaa-row .analyse-article-details article {
    max-width: 100%;
  }
}

.m4 .analyse-container .aaa-row .analyse-article-details article p {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (width <= 767px) {
  .m4 .analyse-container .aaa-row .analyse-article-details article p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
}

.m4 .analyse-container .aaa-row .analyse-article-details .vector-img {
  justify-content: flex-end;
  padding-top: 50px;
  display: flex;
}

@media (width <= 991px) {
  .m4 .analyse-container .aaa-row .analyse-article-details .vector-img {
    display: none;
  }
}

.m4 .analyse-container .aaa-row .analyse-article-details .vector-img figure img {
  max-width: 248px;
  height: 258px;
}

.m4 .analyse-container .aaa-row .analyse-col {
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  width: 100%;
  display: flex;
}

@media (width <= 767px) {
  .m4 .analyse-container .aaa-row .analyse-col {
    flex-direction: column;
    align-items: flex-start;
  }
}

.m4 .analyse-container .aaa-row .analyse-col .heading-wrap {
  max-width: 452px;
}

.m4 .analyse-container .aaa-row .analyse-col .heading-wrap h2 {
  color: #fd0095;
  font-family: Manrope;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (width <= 767px) {
  .m4 .analyse-container .aaa-row .analyse-col .heading-wrap h2 {
    font-size: 49px;
  }
}

.m4 .analyse-container .aaa-row .analyse-col .content-wrap {
  max-width: 686px;
}

.m4 .analyse-container .aaa-row .analyse-col .content-wrap p {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (width <= 767px) {
  .m4 .analyse-container .aaa-row .analyse-col .content-wrap p {
    font-size: 20px;
  }
}

@media (width <= 1599px) {
  .m4 .svg-stroke {
    top: 90px;
  }
}

@media (width <= 1399px) {
  .m4 .svg-stroke {
    top: 124px;
  }
}

@media (width <= 1199px) {
  .m4 .svg-stroke {
    top: 110px;
    right: 0;
  }
}

@media (width <= 991px) {
  .m4 .svg-stroke {
    display: none;
  }
}

.m4 .container .infographic {
  justify-content: flex-end;
  margin-top: -55px;
  display: flex;
}

.m4 .container .infographic figure img {
  max-width: 602px;
  height: 429px;
}

@media (width <= 991px) {
  .m4 .container .infographic figure img {
    max-width: 100%;
    height: auto;
  }
}

.m4 .svg-hold svg {
  min-width: 119%;
  position: absolute;
  top: 253px;
  right: 0;
}

.m4 .container {
  padding-top: 63px;
}

@media (width <= 991px) {
  .m4 .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width <= 767px) {
  .m4 .container {
    padding-top: 0;
  }
}

.m4 .container h3 {
  color: #000;
  padding-bottom: 30px;
  font-family: Manrope;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (width <= 991px) {
  .m4 .container h3 {
    max-width: 185px;
    padding-bottom: 20px;
    font-size: 25px;
  }
}

.m4 .container .cards-row {
  justify-content: center;
  gap: 30px;
}

@media (width <= 767px) {
  .m4 .container .cards-row {
    justify-content: center;
  }
}

.m4 .container .cards-row .card-col {
  max-width: 258px;
}

@media (width <= 767px) {
  .m4 .container .cards-row .card-col {
    max-width: 301px;
  }
}

.m4 .container .cards-row .card-col article figure img {
  max-width: 300px;
  height: 330px;
}

@media (width <= 767px) {
  .m4 .container .cards-row .card-col article figure img {
    max-width: 100%;
  }
}

.m4 .container .cards-row .card-col .slide-wrap {
  background: #f6e4ec;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  max-width: 269px;
  height: 380px;
  padding: 24px;
  display: flex;
}

@media (width <= 767px) {
  .m4 .container .cards-row .card-col .slide-wrap {
    max-width: 301px;
    width: 301px !important;
  }
}

.m4 .container .cards-row .card-col .slide-wrap figure img {
  max-width: 97px;
  height: 96px;
}

.m4 .container .cards-row .card-col .slide-wrap p {
  width: 210px;
}

@media (width <= 767px) {
  .m4 .container .cards-row .img-card-col {
    display: none;
  }
}

.m4 .container .cards-row .col3 article .slider-neww {
  gap: 24px;
  display: flex;
}

.m4 .container .cards-row .col3 article .slider-neww .slide-wrap {
  background: #f6e4ec;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 297px;
  height: 380px;
  padding: 24px;
  display: flex;
}

.m4 .container .cards-row .col3 article .slider-neww .slide-wrap figure img {
  max-width: 97px;
  height: 96px;
}

.m4 .container .cards-row .col3 article .slider-neww .slide-wrap p {
  width: 210px;
}

.m4 .container .cards-row .col3 article .slider-neww .slick-track {
  gap: 10px;
  display: flex;
}

@media (width <= 991px) {
  .step-1 {
    display: none;
  }
}

.m6 {
  padding: 150px 0 190px;
}

.m6 .container .row .col5 article {
  position: relative;
}

.m6 .container .row .col5 article .left-heading-wrap {
  flex-direction: column;
  gap: 26px;
  display: flex;
}

.m6 .container .row .col5 article .left-heading-wrap h2 {
  color: #000;
  max-width: 427px;
  font-family: Manrope;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%;
}

.m6 .container .row .col5 article .magnifying {
  justify-content: flex-end;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.m6 .container .row .col5 article .magnifying figure img {
  width: 236px;
  height: 340px;
}

.m6 .container .sticky-last {
  padding-top: 40px;
}

@media (width <= 767px) {
  .m6 .container .sticky-last {
    display: none;
  }
}

.m6 .loupe {
  cursor: none;
  border-radius: 50%;
  width: 260px;
  height: 260px;
  display: none;
  position: absolute;
  overflow: hidden;
}

.m6 .loupe-svg {
  z-index: 1;
  cursor: none;
  width: 200px;
  height: 200px;
  display: none;
  position: absolute;
}

.m6 .loupe img {
  max-width: 800%;
  max-height: 800%;
  position: absolute;
  right: 0;
}

.m6 .sticky-notes {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

@media (width <= 1199px) {
  .m6 .sticky-notes .note figure img {
    max-width: 119px;
    height: 119px;
  }
}

.m3-new {
  padding: 90px 0;
}

@media (width <= 767px) {
  .m3-new {
    padding: 40px 0;
  }
}

.m3-new .container .heading-wrap {
  text-align: center;
}

@media (width <= 767px) {
  .m3-new .container .heading-wrap {
    text-align: left;
  }
}

.m3-new .container .heading-wrap h2 {
  font-family: Manrope;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.m3-new .container .heading-wrap span {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.m3-new .m3-new-row {
  justify-content: space-evenly;
  gap: 30px;
  padding-top: 85px;
}

@media (width <= 767px) {
  .m3-new .m3-new-row {
    padding-top: 36px;
  }
}

.m3-new .m3-new-row .hand article {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m3-new .m3-new-row .hand article h3 {
  color: #fd0095;
  font-family: Manrope;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.m3-new .m3-new-row .hand article span {
  color: #000;
  text-align: center;
  max-width: 164px;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.m3-new .m3-new-row .targeting {
  background-image: url("../targeting.c2179cc0.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  max-width: 323px;
  height: 306px;
  display: flex;
}

.m3-new .m3-new-row .personalisation {
  background-image: url("../personalisation.b320f0f6.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  max-width: 323px;
  height: 306px;
  display: flex;
}

.m3-new .m3-new-row .orchestration {
  background-image: url("../orchestration-new.f16df36f.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  max-width: 323px;
  height: 306px;
  display: flex;
}

.m3-new .m3-new-row .conversation {
  background-image: url("../conversation-automation.9e70adae.png");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  max-width: 323px;
  height: 306px;
  display: flex;
}

.m3-new .m3-new-row .audience {
  background-image: url("../audience-insights.3b073bf3.png");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  max-width: 323px;
  height: 306px;
  display: flex;
}

@keyframes roll {
  100% {
    transform: translateX(170px)rotate(360deg);
  }
}

@keyframes rollback {
  0% {
    transform: translateX(170px)rotate(360deg);
  }

  100% {
    transform: translateX(0)rotate(0);
  }
}

.codepen-scroll-eff .col-point {
  border-radius: 5px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
  position: absolute;
  bottom: -8rem;
  right: 20px;
}

.codepen-scroll-eff .wrapper {
  overflow-y: auto;
}

.codepen-scroll-eff ::-webkit-scrollbar {
  width: 5px;
}

.codepen-scroll-eff ::-webkit-scrollbar-track {
  background-color: #fff0;
}

.codepen-scroll-eff ::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  width: 3px;
  height: 10px;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }

  100% {
    --gradient-angle: 360deg;
  }
}

.codepen-scroll-eff #div1 {
  width: fit-content;
}

.codepen-scroll-eff #div2 {
  background: url("../Golf-Ball-Hole.cc1c3dfe.svg") center no-repeat;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 120px;
  min-height: 80px;
  margin-left: 70%;
  display: flex;
}

.codepen-scroll-eff #div2 .ball {
  background: url("../ball-2.db1f53df.svg") bottom no-repeat;
  width: 85px;
  height: 60px;
}

.ball {
  background: url("../ball-1.ad4c3635.svg") center no-repeat;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.fd-contact-us {
  color: #fd0095;
  z-index: 9;
  cursor: pointer;
  background: #f6e4ec;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 245px;
  height: 50px;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100px;
  transform: rotate(-90deg);
}

@media (width <= 991px) {
  .fd-contact-us {
    width: 200px;
    right: -75px;
  }
}

@media (width <= 767px) {
  .fd-contact-us {
    width: 150px;
    height: 43px;
    font-size: 15px;
    right: -55px;
  }
}

.fd-contact-us svg {
  transform: rotate(90deg);
}

@media (width <= 767px) {
  .fd-contact-us svg {
    width: 12px;
    height: 12px;
  }
}

.form-wrap {
  z-index: 99;
  width: 100%;
  margin: auto;
  padding: 20px 0;
  transition: all .25s ease-in-out;
  position: absolute;
  left: 0;
}

.form-wrap:after {
  content: "";
  z-index: -1;
  background: #0009;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.form-wrap .content {
  background: #fff;
  max-width: 720px;
  margin: auto;
  left: 0;
  right: 0;
}

@media (width <= 767px) {
  .form-wrap .content {
    max-width: 550px;
  }
}

@media (width <= 575px) {
  .form-wrap .content {
    max-width: 90%;
  }
}

.form-wrap .content .head {
  background: #f6e4ec;
  padding: 30px 60px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 767px) {
  .form-wrap .content .head {
    padding: 20px 20px 0;
  }
}

@media (width <= 576px) {
  .form-wrap .content .head {
    padding: 15px 15px 0;
  }
}

.form-wrap .content .head h2 {
  color: #fd0095;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 767px) {
  .form-wrap .content .head h2 {
    font-size: 35px;
  }
}

@media (width <= 575px) {
  .form-wrap .content .head h2 {
    font-size: 20px;
  }
}

.form-wrap .content .head svg {
  flex: 0 0 200px;
  width: 201px;
  margin: 0 0 -15px;
}

@media (width <= 767px) {
  .form-wrap .content .head svg {
    flex: 0 0 140px;
    width: 140px;
    height: 120px;
  }
}

@media (width <= 575px) {
  .form-wrap .content .head svg {
    flex: 0 0 80px;
    width: 40px;
    height: 80px;
  }
}

.form-wrap .content .head .close {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30px;
  right: 20px;
  flex: auto !important;
}

@media (width <= 767px) {
  .form-wrap .content .head .close {
    width: 30px;
    height: 30px;
    right: 10px;
  }
}

@media (width <= 575px) {
  .form-wrap .content .head .close {
    width: 20px;
    height: 20px;
    top: 25px;
    right: 5px;
  }
}

.form-wrap .content .forms {
  background: #fff;
  padding: 40px 60px;
}

@media (width <= 767px) {
  .form-wrap .content .forms {
    padding: 30px 20px;
  }
}

@media (width <= 575px) {
  .form-wrap .content .forms {
    padding: 20px 15px;
  }
}

.form-wrap .content .forms h3 {
  color: #fd0095;
  padding: 0 0 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

@media (width <= 575px) {
  .form-wrap .content .forms h3 {
    padding: 0 0 20px;
    font-size: 20px;
  }
}

.form-wrap .content .forms .input {
  color: #000;
  border: 0;
  border-bottom: 1px solid #000;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px 0;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  display: block;
  outline: 0 !important;
}

@media (width <= 575px) {
  .form-wrap .content .forms .input {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.form-wrap .content .forms select {
  color: #000;
  border: 0;
  border-bottom: 1px solid #000;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px 0;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  display: block;
  outline: 0 !important;
}

@media (width <= 575px) {
  .form-wrap .content .forms select {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.form-wrap .content .forms .privcy {
  gap: 10px;
  padding: 0 0 20px;
  display: flex;
}

.form-wrap .content .forms .privcy .check {
  border: 1px solid #000;
  width: 20px;
  height: 20px;
}

.form-wrap .content .forms .privcy label {
  color: #000;
  max-width: 470px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
}

@media (width <= 575px) {
  .form-wrap .content .forms .privcy label {
    font-size: 14px;
  }
}

.form-wrap .content .forms .privcy label a {
  color: #fd0095;
  text-decoration: none;
}

@media (width <= 575px) {
  .form-wrap .content .forms .privcy label a {
    font-size: 14px;
  }
}

.form-wrap .content .forms .privcy label a:hover {
  color: #000;
}

.form-wrap .content .forms .btn {
  display: flex;
  position: relative;
}

.form-wrap .content .forms .btn .submit {
  cursor: pointer;
  color: #fff;
  background: #fd0095;
  border: 0;
  outline: 0;
  padding: 10px 60px 12px;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

@media (width <= 575px) {
  .form-wrap .content .forms .btn .submit {
    font-size: 16px;
  }
}

.form-wrap .content .forms .btn svg {
  margin: 16px 0 0 -50px;
}

@media (width <= 575px) {
  .form-wrap .content .forms .btn svg {
    width: 12px;
    height: 12px;
  }
}

.clickpopup {
  background-color: #fff;
  height: auto !important;
}

.clickpopup .form-wrap {
  transition: all .25s ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: all !important;
}

.clickpopup body {
  background-color: #fff;
  height: auto !important;
}

.code-search-wrap {
  background: #fd0095;
  padding: 150px 0 0;
  position: relative;
}

@media (width <= 991px) {
  .code-search-wrap {
    background-size: 840px;
    padding: 100px 0 60px;
  }
}

@media (width <= 767px) {
  .code-search-wrap {
    background-size: 610px;
    padding: 100px 0 60px;
  }
}

@media (width <= 575px) {
  .code-search-wrap {
    background-size: 420px;
    padding: 100px 0 60px;
  }
}

.code-search-wrap h2 {
  text-align: center;
  color: #fff;
  padding-bottom: 30px;
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 991px) {
  .code-search-wrap h2 {
    font-size: 56px;
  }
}

@media (width <= 767px) {
  .code-search-wrap h2 {
    text-align: left;
    font-size: 31px;
  }
}

@media (width <= 991px) {
  .code-search-wrap .reimagine-stroke {
    display: none;
  }
}

.code-search-wrap .reimagine-stroke svg {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.code-search-wrap .crossing-img-wrap {
  z-index: 1;
  padding-top: 150px;
  position: relative;
}

.code-search-wrap .crossing-img-wrap img {
  margin: 0 auto;
  display: block;
}

@media (width <= 991px) {
  .code-search-wrap .crossing-img-wrap .desk, .code-search-wrap .crossing-img-wrap {
    display: none;
  }
}

.code-search-wrap .mob {
  width: 100%;
  display: none;
}

@media (width <= 991px) {
  .code-search-wrap .mob {
    display: block;
  }
}

.highlight-wrap {
  background: #f3f3f3;
}

.highlight-wrap .txt {
  padding: 80px 0;
}

@media (width <= 991px) {
  .highlight-wrap .txt {
    padding: 50px 0;
  }
}

.highlight-wrap h2 {
  color: #fd0095;
  padding: 0 0 20px;
  font-size: 49px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 991px) {
  .highlight-wrap h2 {
    font-size: 42px;
  }
}

@media (width <= 767px) {
  .highlight-wrap h2 {
    padding: 0 0 10px;
    font-size: 25px;
  }
}

.highlight-wrap p {
  color: #000;
  padding: 0;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.4;
}

@media (width <= 991px) {
  .highlight-wrap p {
    font-size: 19px;
  }
}

@media (width <= 767px) {
  .highlight-wrap p {
    font-size: 16px;
  }
}

.highlight-wrap .img {
  height: 100%;
  position: relative;
}

.highlight-wrap .img img {
  position: absolute;
  bottom: 0;
  right: -15px;
}

@media (width <= 991px) {
  .highlight-wrap .img img {
    position: inherit;
    max-width: 300px;
    margin: auto;
    display: block;
    right: 0;
  }
}

@media (width <= 767px) {
  .highlight-wrap .img img {
    max-width: 140px;
  }
}

.ethical {
  padding: 0 0 50px;
}

.ethical .ethical-wrap {
  z-index: 1;
  padding: 0 0 150px;
  position: relative;
}

@media (width <= 1299px) {
  .ethical .ethical-wrap {
    padding: 0 0 190px;
  }
}

@media (width <= 991px) {
  .ethical .ethical-wrap {
    padding: 0;
  }
}

.ethical .ethical-wrap .ethical-m0b-img {
  display: none;
}

@media (width <= 991px) {
  .ethical .ethical-wrap .ethical-m0b-img {
    display: block;
  }
}

.ethical .ethical-wrap .ethical-m0b-img img {
  width: 100%;
}

.ethical .ethical-wrap .ethical-stroke {
  bottom: 45px;
}

@media (width <= 1399px) {
  .ethical .ethical-wrap .ethical-stroke {
    bottom: 5px;
  }
}

@media (width <= 991px) {
  .ethical .ethical-wrap .ethical-stroke {
    display: none;
  }
}

.ethical .ethical-wrap .ethical-stroke svg {
  position: absolute;
  bottom: -11px;
  right: 0;
}

@media (width <= 1399px) {
  .ethical .ethical-wrap .ethical-stroke svg path {
    stroke-width: 10px;
  }
}

.ethical .ethical-wrap .stick-men {
  position: absolute;
  bottom: 243px;
  right: 0;
}

@media (width <= 1399px) {
  .ethical .ethical-wrap .stick-men {
    bottom: 211px;
  }
}

@media (width <= 991px) {
  .ethical .ethical-wrap .stick-men {
    display: none;
  }
}

.ethical .ethical-wrap .stick-men figure img {
  max-width: 450px;
}

@media (width <= 1399px) {
  .ethical .ethical-wrap .stick-men figure img {
    max-width: 340px;
  }
}

.ethical .ethical-wrap .txt {
  margin: 0 -160px 0 0;
}

@media (width <= 991px) {
  .ethical .ethical-wrap .txt {
    margin: 0;
  }
}

.ethical .ethical-wrap .txt {
  padding: 0 0 50px;
}

.ethical .ethical-wrap h2 {
  color: #000;
  padding: 0 0 20px;
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 1199px) {
  .ethical .ethical-wrap h2 {
    font-size: 68px;
  }
}

@media (width <= 991px) {
  .ethical .ethical-wrap h2 {
    font-size: 55px;
  }
}

@media (width <= 767px) {
  .ethical .ethical-wrap h2 {
    font-size: 25px;
  }
}

.ethical .ethical-wrap p {
  color: #000;
  padding-right: 120px;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.4;
}

@media (width <= 1199px) {
  .ethical .ethical-wrap p {
    font-size: 22px;
  }
}

@media (width <= 991px) {
  .ethical .ethical-wrap p {
    padding-right: 0;
  }
}

@media (width <= 767px) {
  .ethical .ethical-wrap p {
    font-size: 16px;
  }
}

.ethical .ethical-wrap p + p {
  padding-top: 30px;
}

.info-share-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (width <= 991px) {
  .info-share-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.info-share-wrap .txt {
  float: right;
  max-width: 340px;
}

@media (width <= 991px) {
  .info-share-wrap .txt {
    float: none;
    max-width: 100%;
  }
}

.info-share-wrap h2 {
  color: #000;
  padding: 0 0 10px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
}

@media (width <= 575px) {
  .info-share-wrap h2 {
    font-size: 16px;
  }
}

.info-share-wrap p {
  color: #000;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (width <= 575px) {
  .info-share-wrap p {
    font-size: 16px;
  }
}

.info-share-wrap .video {
  width: 100%;
  max-width: 680px;
  position: relative;
}

@media (width <= 991px) {
  .info-share-wrap .video {
    margin: 0 0 20px;
  }
}

.info-share-wrap .video img {
  display: block;
}

.info-share-wrap .video svg {
  width: 111px;
  height: 111px;
  margin: auto;
  position: absolute;
  inset: 0;
}

@media (width <= 991px) {
  .info-share-wrap .video svg {
    width: 50px;
    height: 50px;
  }
}

.info-share-wrap.order-change .one {
  order: 2;
}

@media (width <= 991px) {
  .info-share-wrap.order-change .one {
    order: 1;
  }
}

.info-share-wrap.order-change .two {
  order: 1;
}

@media (width <= 991px) {
  .info-share-wrap.order-change .two {
    order: 2;
  }
}

.info-share-wrap.order-change .video {
  float: right;
}

@media (width <= 991px) {
  .info-share-wrap.order-change .video {
    float: none;
  }
}

.info-share-wrap.order-change .txt {
  float: left;
}

@media (width <= 991px) {
  .info-share-wrap.order-change .txt {
    float: none;
  }
}

.full-content {
  background: #f6e4ec;
  max-width: 680px;
  margin: 30px 0 0;
  padding: 24px;
}

@media (width <= 991px) {
  .full-content {
    font-size: 23px;
    display: none;
  }
}

@media (width <= 575px) {
  .full-content {
    padding: 15px;
    font-size: 16px;
  }
}

.full-content svg {
  margin: 15px 0 0;
}

@media (width <= 767px) {
  .full-content svg {
    width: 14px;
    margin: 11px 0 0;
  }
}

@media (width <= 575px) {
  .full-content svg {
    font-size: 20px;
  }
}

.full-content.mob {
  max-width: 100%;
  display: none;
}

@media (width <= 991px) {
  .full-content.mob {
    display: block;
  }
}

.full-content .head {
  color: #fd0095;
  cursor: pointer;
  justify-content: space-between;
  font-size: 25px;
  font-weight: 400;
  display: flex;
}

@media (width <= 991px) {
  .full-content .head {
    font-size: 22px;
  }
}

.full-content .para {
  padding: 15px 0 0;
}

.full-content .para p {
  color: #000;
  padding: 0 0 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (width <= 991px) {
  .full-content .para p {
    font-size: 16px;
  }
}

.full-content .para ul {
  margin: 0 0 0 20px;
}

.full-content .para ul li {
  color: #000;
  padding: 0 0 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  list-style: number;
}

@media (width <= 991px) {
  .full-content .para ul li {
    font-size: 16px;
  }
}

.full-content .para ul li ol {
  margin: 0 0 0 20px;
}

.full-content .para ul li ol li {
  padding: 5px 0 0;
  list-style: lower-alpha;
}

.help-wrap {
  text-align: center;
  background: #fd0095 no-repeat;
  padding: 70px 0 80px;
  position: relative;
}

@media (width <= 991px) {
  .help-wrap {
    background-position: 50% 80px;
    background-size: 940px;
    padding: 60px 0;
  }
}

@media (width <= 767px) {
  .help-wrap {
    background-size: 740px;
    padding: 60px 0;
  }
}

@media (width <= 575px) {
  .help-wrap {
    background-position: 50% 50px;
    background-size: 430px;
    padding: 60px 0;
  }
}

.help-wrap .ai-stroke {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

@media (width <= 991px) {
  .help-wrap .ai-stroke {
    display: none;
  }
}

.help-wrap .main-img {
  margin: 20px 0;
  display: none;
}

@media (width <= 991px) {
  .help-wrap .main-img {
    display: block;
  }
}

.help-wrap .main-img img {
  width: auto;
  height: auto;
  display: block;
}

.help-wrap figure img {
  max-width: 760px;
  height: 452px;
}

@media (width <= 767px) {
  .help-wrap figure img {
    max-width: 100%;
    height: auto;
  }
}

@media (width <= 991px) {
  .help-wrap figure {
    display: none;
  }
}

.help-wrap h2 {
  color: #fff;
  padding: 0 0 20px;
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 991px) {
  .help-wrap h2 {
    font-size: 56px;
  }
}

@media (width <= 767px) {
  .help-wrap h2 {
    text-align: left;
    font-size: 31px;
  }
}

.help-wrap p {
  color: #fff;
  padding: 0 0 50px;
  font-size: 31px;
  font-weight: 400;
  line-height: 1.2;
}

@media (width <= 991px) {
  .help-wrap p {
    padding-bottom: 20px;
    font-size: 22px;
  }
}

@media (width <= 767px) {
  .help-wrap p {
    text-align: left;
    font-size: 16px;
  }

  .help-wrap .btn {
    text-align: left;
  }
}

.help-wrap .btn a {
  color: #fd0095;
  cursor: pointer;
  background: #fff;
  align-items: center;
  gap: 8px;
  padding: 10px 30px 8px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
}

.help-wrap .btn a svg {
  margin: 0 0 -1px;
}

.help-wrap.second {
  background-position: 50% 80px;
  padding: 130px 0 80px;
}

@media (width <= 991px) {
  .help-wrap.second {
    background-position: 50% 80px;
    background-size: 780px;
    padding: 100px 0 60px;
  }
}

@media (width <= 767px) {
  .help-wrap.second {
    background-position: 50% 50px;
    background-size: 580px;
    padding: 100px 0 60px;
  }
}

@media (width <= 575px) {
  .help-wrap.second {
    background-size: 430px;
    padding: 100px 0 60px;
  }
}

.help-wrap.second .main-img {
  display: none;
}

@media (width <= 991px) {
  .help-wrap.second .main-img {
    width: 100%;
    margin: 50px 0 0;
    display: block;
  }
}

.help-wrap.second .second-stroke {
  margin: auto;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
}

@media (width <= 991px) {
  .help-wrap.second .second-stroke {
    display: none;
  }
}

.help-wrap.second .txt {
  flex-direction: column;
  gap: 70px;
  display: flex;
}

@media (width <= 991px) {
  .help-wrap.second .txt figure {
    display: none;
  }
}

.help-wrap.second .txt figure img {
  max-width: 874px;
  height: 525px;
}

@media (width <= 767px) {
  .help-wrap.second .txt figure img {
    max-width: 100%;
    height: auto;
  }
}

.help-wrap.second .txt h2 {
  font-size: 88px;
}

@media (width <= 991px) {
  .help-wrap.second .txt h2 {
    font-size: 56px;
  }
}

@media (width <= 767px) {
  .help-wrap.second .txt h2 {
    text-align: left;
    max-width: 300px;
    font-size: 31px;
  }
}

.help-wrap.second .txt p {
  max-width: 920px;
  margin: 0 auto;
  padding: 0;
  font-size: 25px;
}

@media (width <= 991px) {
  .help-wrap.second .txt p {
    font-size: 22px;
  }
}

@media (width <= 767px) {
  .help-wrap.second .txt p {
    text-align: left;
    font-size: 16px;
  }
}

.step1-wrap {
  min-height: 940px;
  padding: 160px 0;
}

@media (width <= 991px) {
  .step1-wrap {
    padding: 80px 0;
    display: none;
  }
}

.step1-wrap .content {
  min-height: 610px;
  position: relative;
}

@media (width <= 1199px) {
  .step1-wrap .content {
    min-height: 510px;
  }
}

@media (width <= 991px) {
  .step1-wrap .content {
    min-height: 400px;
  }
}

.step1-wrap .content .tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 15px no-repeat;
  padding: 2px 0 2px 20px;
  font-size: 16px;
  font-weight: 400;
}

.step1-wrap .content h2 {
  color: #000;
  font-size: 88px;
  font-weight: 700;
  line-height: 1.1;
}

@media (width <= 1199px) {
  .step1-wrap .content h2 {
    font-size: 71px;
  }
}

@media (width <= 991px) {
  .step1-wrap .content h2 {
    font-size: 55px;
  }
}

.step1-wrap .content .main {
  max-width: inherit;
  pointer-events: none;
  position: absolute;
  top: 37px;
  left: 0;
}

@media (width <= 1199px) {
  .step1-wrap .content .main {
    max-width: 100%;
  }
}

.step1-wrap .content .txt {
  color: #000;
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 991px) {
  .step1-wrap .content .txt {
    font-size: 7px;
  }
}

.step1-wrap .content .txt.txt1 {
  width: 125px;
  display: none;
  top: 60px;
  left: 620px;
}

@media (width <= 1199px) {
  .step1-wrap .content .txt.txt1 {
    top: 48px;
    left: 502px;
  }
}

@media (width <= 991px) {
  .step1-wrap .content .txt.txt1 {
    width: 92px;
    top: 43px;
    left: 385px;
  }
}

.step1-wrap .content .txt.txt2 {
  width: 110px;
  display: none;
  top: 460px;
  left: 820px;
}

@media (width <= 1199px) {
  .step1-wrap .content .txt.txt2 {
    top: 380px;
    left: 670px;
  }
}

@media (width <= 991px) {
  .step1-wrap .content .txt.txt2 {
    width: 92px;
    top: 305px;
    left: 506px;
  }
}

.step1-wrap .container .magnifier-row {
  position: relative;
}

.step1-wrap .container .magnifier-row article {
  position: absolute;
  top: 0;
  right: 0;
}

.step1-wrap .magnify {
  margin: 20px auto;
  display: inline-block;
  position: relative;
}

.step1-wrap .magnify .magnified {
  z-index: 10;
  border: 5px solid #fff;
  width: 600px;
  height: 450px;
  margin: auto;
  display: block;
}

.step1-wrap .magnify .magnifier {
  z-index: 20;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 1000%;
  border: 4px solid #fff;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: none;
  position: absolute;
  margin-top: -100px !important;
  margin-left: -100px !important;
}

.step2-wrap {
  background: #f3f3f3;
  padding: 150px 0;
}

@media (width <= 991px) {
  .step2-wrap {
    padding: 80px 0;
  }
}

@media (width <= 767px) {
  .step2-wrap {
    display: none;
  }
}

.step2-wrap .connecting-container {
  position: absolute;
  top: -20px;
}

.step2-wrap .content {
  min-height: 590px;
  position: relative;
}

@media (width <= 1199px) {
  .step2-wrap .content {
    min-height: 405px;
  }
}

@media (width <= 991px) {
  .step2-wrap .content {
    min-height: 315px;
  }
}

.step2-wrap .content .tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 15px no-repeat;
  padding: 2px 0 2px 20px;
  font-size: 16px;
  font-weight: 400;
}

.step2-wrap .content h2 {
  color: #000;
  font-size: 88px;
  font-weight: 700;
  line-height: 1.1;
}

@media (width <= 1199px) {
  .step2-wrap .content h2 {
    font-size: 54px;
  }
}

@media (width <= 991px) {
  .step2-wrap .content h2 {
    font-size: 47px;
  }
}

.step2-wrap .content h2.one {
  margin: 10px 0 0;
}

@media (width <= 991px) {
  .step2-wrap .content h2.one {
    margin: 0;
    font-size: 47px;
  }
}

.step2-wrap .content h2.two {
  position: absolute;
  top: 370px;
  left: 240px;
}

@media (width <= 1199px) {
  .step2-wrap .content h2.two {
    top: 260px;
    left: 310px;
  }
}

@media (width <= 991px) {
  .step2-wrap .content h2.two {
    top: 200px;
    left: 240px;
  }
}

.step2-wrap .content figure .step-2-svg {
  position: absolute;
  bottom: -104px;
  left: -132px;
}

.step2-wrap .content .main {
  max-width: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 1199px) {
  .step2-wrap .content .main {
    max-width: 100%;
  }
}

.step2-wrap .content .txt {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 991px) {
  .step2-wrap .content .txt {
    font-size: 14px;
  }
}

.step2-wrap .content .txt.txt1 {
  width: 260px;
  top: -30px;
  left: 510px;
}

@media (width <= 1199px) {
  .step2-wrap .content .txt.txt1 {
    top: -40px;
    left: 450px;
  }
}

@media (width <= 991px) {
  .step2-wrap .content .txt.txt1 {
    width: 210px;
    left: 350px;
  }
}

.step2-wrap .content .txt.txt2 {
  width: 195px;
  top: 440px;
  left: 1010px;
}

@media (width <= 1199px) {
  .step2-wrap .content .txt.txt2 {
    width: 130px;
    font-size: 13px;
    top: 280px;
    left: 842px;
  }
}

@media (width <= 991px) {
  .step2-wrap .content .txt.txt2 {
    width: 110px;
    font-size: 11px;
    top: 230px;
    left: 640px;
  }
}

.step3-wrap {
  padding: 110px 0;
}

@media (width <= 991px) {
  .step3-wrap {
    padding: 80px 0;
  }
}

@media (width <= 767px) {
  .step3-wrap {
    display: none;
  }
}

.step3-wrap .content {
  min-height: 820px;
  position: relative;
}

@media (width <= 991px) {
  .step3-wrap .content {
    min-height: 560px;
  }
}

.step3-wrap .content .tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 15px no-repeat;
  padding: 2px 0 2px 20px;
  font-size: 16px;
  font-weight: 400;
}

.step3-wrap .content h2 {
  color: #000;
  font-size: 88px;
  font-weight: 700;
  line-height: 1.1;
}

@media (width <= 991px) {
  .step3-wrap .content h2 {
    font-size: 60px;
  }
}

.step3-wrap .content .main {
  max-width: inherit;
  pointer-events: none;
  position: absolute;
  top: 110px;
  right: 30px;
}

@media (width <= 991px) {
  .step3-wrap .content .main {
    max-width: 580px;
  }
}

.step3-wrap .content .txt {
  color: #000;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 991px) {
  .step3-wrap .content .txt {
    font-size: 14px;
  }
}

.step3-wrap .content .txt.txt1 {
  width: 280px;
  top: 280px;
  left: 850px;
}

@media (width <= 1199px) {
  .step3-wrap .content .txt.txt1 {
    left: 640px;
  }
}

@media (width <= 991px) {
  .step3-wrap .content .txt.txt1 {
    width: 200px;
    top: 190px;
    left: 560px;
  }
}

.step3-wrap .content .txt.txt2 {
  width: 280px;
  top: 500px;
  left: 730px;
}

@media (width <= 1199px) {
  .step3-wrap .content .txt.txt2 {
    left: 530px;
  }
}

@media (width <= 991px) {
  .step3-wrap .content .txt.txt2 {
    width: 200px;
    top: 300px;
    left: 500px;
  }
}

.step3-wrap .content .step-3-txt {
  opacity: 0;
}

.step4-wrap {
  background: #f3f3f3;
  padding: 110px 0;
}

@media (width <= 991px) {
  .step4-wrap {
    padding: 80px 0;
  }
}

@media (width <= 767px) {
  .step4-wrap {
    display: none;
  }
}

.step4-wrap .content {
  position: relative;
}

@media (width <= 991px) {
  .step4-wrap .content {
    min-height: 550px;
  }
}

.step4-wrap .content .tip {
  color: #fd0095;
  background: url("../bulb.e3ccfcb8.svg") 0 / 15px no-repeat;
  padding: 2px 0 2px 20px;
  font-size: 16px;
  font-weight: 400;
}

.step4-wrap .content h2 {
  color: #000;
  font-size: 88px;
  font-weight: 700;
  line-height: 1.1;
}

@media (width <= 991px) {
  .step4-wrap .content h2 {
    font-size: 65px;
  }
}

.step4-wrap .content .main {
  max-width: inherit;
  pointer-events: none;
  position: absolute;
  top: 180px;
  left: 0;
}

@media (width <= 1199px) {
  .step4-wrap .content .main {
    max-width: 100%;
  }
}

.step4-wrap .content .txt {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 991px) {
  .step4-wrap .content .txt {
    font-size: 14px;
  }
}

.step4-wrap .content .txt.txt1 {
  width: 305px;
  top: 170px;
  left: 260px;
}

@media (width <= 1199px) {
  .step4-wrap .content .txt.txt1 {
    width: 255px;
    top: 150px;
    left: 210px;
  }
}

@media (width <= 991px) {
  .step4-wrap .content .txt.txt1 {
    width: 180px;
    top: 160px;
    left: 170px;
  }
}

.step4-wrap .content .txt.txt2 {
  width: 260px;
  top: 130px;
  left: 760px;
}

@media (width <= 1199px) {
  .step4-wrap .content .txt.txt2 {
    top: 150px;
    left: 610px;
  }
}

@media (width <= 991px) {
  .step4-wrap .content .txt.txt2 {
    width: 180px;
    top: 170px;
    left: 460px;
  }
}

.step-1-mob-wrap {
  padding: 80px 15px 50px;
  position: relative;
  overflow: hidden;
}

@media (width <= 359px) {
  .step-1-mob-wrap {
    padding: 80px 0 50px;
  }
}

.step-1-mob-wrap .main {
  max-width: 97px;
  position: absolute;
  top: 25px;
  right: 15px;
}

@media (width <= 359px) {
  .step-1-mob-wrap .main {
    max-width: 80px;
  }
}

.step-1-mob-wrap .content h2 {
  color: #000;
  padding: 0 0 50px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

.step-1-mob-wrap .content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.step-1-mob-wrap .content .txt1 {
  background: url("../mob-step-11-bg.d1de0a7b.png") 0 0 / 100% no-repeat;
  width: 280px;
  height: 300px;
  margin: 0 0 30px;
  padding: 30px 50px 0 25px;
}

.step-1-mob-wrap .content .txt2 {
  background: url("../mob-step-1-bg.0556e311.png") 0 0 / 100% no-repeat;
  width: 280px;
  height: 270px;
  margin: 0 0 0 40px;
  padding: 50px 50px 0 55px;
}

@media (width <= 359px) {
  .step-1-mob-wrap .content .txt2 {
    margin: 0;
  }
}

.step-2-mob-wrap {
  background: #f3f3f3;
  padding: 80px 15px 50px;
  position: relative;
  overflow: hidden;
}

@media (width <= 359px) {
  .step-2-mob-wrap {
    padding: 80px 0 50px;
  }
}

.step-2-mob-wrap .main {
  max-width: 80px;
  position: absolute;
  top: 15px;
  right: 0;
}

.step-2-mob-wrap .content h2 {
  color: #000;
  padding: 0 0 25px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

.step-2-mob-wrap .content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.step-2-mob-wrap .content .txt1 {
  padding: 0 0 20px;
}

.step-2-mob-wrap .content .main1 {
  margin: 0 -30px 30px;
}

.step-3-mob-wrap {
  padding: 80px 15px 50px;
  position: relative;
  overflow: hidden;
}

@media (width <= 359px) {
  .step-3-mob-wrap {
    padding: 80px 0 50px;
  }
}

.step-3-mob-wrap .main {
  max-width: 80px;
  position: absolute;
  top: 30px;
  right: 20px;
}

.step-3-mob-wrap .content h2 {
  color: #000;
  padding: 0 0 25px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

.step-3-mob-wrap .content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.step-3-mob-wrap .content .txt1 {
  padding: 0 0 30px;
}

.step-3-mob-wrap .content .main1 {
  max-width: 250px;
  margin: 0 0 0 20px;
}

.step-4-mob-wrap {
  background: #f3f3f3;
  min-height: 460px;
  padding: 80px 15px 50px;
  position: relative;
  overflow: hidden;
}

@media (width <= 359px) {
  .step-4-mob-wrap {
    padding: 80px 0 50px;
  }
}

.step-4-mob-wrap .main {
  max-width: 150px;
  margin: 0;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.step-4-mob-wrap .content h2 {
  color: #000;
  padding: 0 0 25px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

.step-4-mob-wrap .content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.step-4-mob-wrap .content .txt1 {
  max-width: 190px;
  padding: 0 0 20px;
}

.step-4-mob-wrap .content .main1 {
  margin: 0 -30px 30px;
}

.stepmob-wrap {
  display: none;
}

@media (width <= 767px) {
  .stepmob-wrap {
    display: block;
  }
}

.stepmob-wrap .container {
  max-width: 360px;
}

@media (width <= 359px) {
  .stepmob-wrap .container {
    max-width: 100%;
  }
}

@media (width <= 991px) {
  .step-1-mob-wrap {
    display: block;
  }
}

.content-2 {
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 40px;
  display: flex;
}

@media (width <= 767px) {
  .content-2 {
    display: none;
  }
}

.content-2 .tree-1 {
  cursor: pointer;
}

.content-2 .tree-1 figure img {
  max-width: 240px;
  height: 616px;
}

.content-2 .tree-2 {
  cursor: pointer;
  position: relative;
}

.content-2 .tree-2 .txt {
  color: #000;
  opacity: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  position: absolute;
}

.content-2 .tree-2 .txt1 {
  width: 305px;
  top: -30px;
}

.content-2 .tree-2 figure img {
  max-width: 178px;
  height: 568px;
}

.content-2 .tree-3 {
  cursor: pointer;
}

.content-2 .tree-3 figure img {
  max-width: 219px;
  height: 617px;
}

.content-2 .tree-4 {
  cursor: pointer;
  position: relative;
}

.content-2 .tree-4 .txt {
  color: #000;
  opacity: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  position: absolute;
}

.content-2 .tree-4 .txt2 {
  width: 260px;
  top: -150px;
}

.content-2 .tree-4 figure img {
  max-width: 180px;
  height: 488px;
}

.content-2 .tree-5 {
  cursor: pointer;
}

.content-2 .tree-5 figure img {
  max-width: 228px;
  height: 615px;
}

.all-step-wrap h2 {
  color: #fd0095;
  padding: 0 0 20px;
  font-family: Manrope;
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 991px) {
  .all-step-wrap h2 br {
    display: none;
  }

  .all-step-wrap h2 {
    font-size: 25px;
    font-weight: 700;
  }
}

.all-step-wrap ul li {
  color: #000;
  margin: 0 0 0 15px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  list-style: disc;
}

@media (width <= 991px) {
  .all-step-wrap ul li {
    font-size: 16px;
  }
}

.all-step-wrap ul li + li {
  padding-top: 15px;
}

.all-step-wrap .flex-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  .all-step-wrap .flex-wrap {
    flex-direction: column;
    gap: 20px;
  }
}

.all-step-wrap .txt {
  flex: 0 0 340px;
}

@media (width <= 991px) {
  .all-step-wrap .txt {
    flex: none;
  }
}

.all-step-wrap .img-wrap img {
  max-width: none;
}

.all-step-wrap .step-1-wrap {
  background: #fff;
  padding: 120px 0;
}

@media (width <= 991px) {
  .all-step-wrap .step-1-wrap {
    padding: 50px 0;
  }

  .all-step-wrap .step-1-wrap .img-wrap {
    display: none;
  }
}

@media (width <= 1199px) {
  .all-step-wrap .step-1-wrap .img-wrap img {
    max-width: 100%;
  }

  .all-step-wrap .step-1-wrap .flex-wrap {
    gap: 20px;
  }
}

.all-step-wrap .step-1-wrap .img-mob {
  display: none;
}

@media (width <= 991px) {
  .all-step-wrap .step-1-wrap .img-mob {
    display: block;
  }
}

.all-step-wrap .step-2-wrap {
  background: #f3f3f3;
  padding: 90px 0;
}

@media (width <= 991px) {
  .all-step-wrap .step-2-wrap {
    padding: 50px 0;
  }

  .all-step-wrap .step-2-wrap .img-wrap {
    display: none;
  }
}

.all-step-wrap .step-2-wrap .img-wrap img {
  margin: -90px 0 0 -270px;
}

@media (width <= 1199px) {
  .all-step-wrap .step-2-wrap .img-wrap img {
    max-width: 690px;
    margin: -100px 0 0 -70px;
  }
}

.all-step-wrap .step-2-wrap .img-mob {
  display: none;
}

@media (width <= 991px) {
  .all-step-wrap .step-2-wrap .img-mob {
    display: block;
  }
}

.all-step-wrap .step-3-wrap {
  background: #fff;
  padding: 120px 0;
}

@media (width <= 991px) {
  .all-step-wrap .step-3-wrap {
    padding: 50px 0;
  }

  .all-step-wrap .step-3-wrap .img-wrap {
    display: none;
  }
}

@media (width <= 1199px) {
  .all-step-wrap .step-3-wrap .flex-wrap {
    gap: 20px;
  }

  .all-step-wrap .step-3-wrap img {
    max-width: 100%;
  }
}

.all-step-wrap .step-3-wrap .img-mob {
  display: none;
}

@media (width <= 991px) {
  .all-step-wrap .step-3-wrap .img-mob {
    display: block;
  }
}

.all-step-wrap .step-4-wrap {
  background: #f3f3f3;
  padding: 90px 0;
}

@media (width <= 991px) {
  .all-step-wrap .step-4-wrap {
    padding: 50px 0;
  }

  .all-step-wrap .step-4-wrap .img-wrap {
    display: none;
  }
}

@media (width <= 1199px) {
  .all-step-wrap .step-4-wrap .flex-wrap {
    gap: 20px;
  }

  .all-step-wrap .step-4-wrap img {
    max-width: 100%;
  }
}

.all-step-wrap .step-4-wrap .img-mob {
  display: none;
}

@media (width <= 991px) {
  .all-step-wrap .step-4-wrap .img-mob {
    display: block;
  }
}

.hero-section .instruction {
  text-align: left;
  color: #fd0095;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 17.3px;
  padding-top: 93px;
  font-family: Manrope;
  font-size: 13px;
  display: flex;
  position: relative;
}

.hero-section .instruction .tooltip-icon {
  width: 12px;
  height: 17.3px;
  overflow: hidden;
}

.hero-section .instruction .section-name1 {
  align-items: center;
  width: 427px;
  line-height: 120%;
  display: flex;
}

.hero-section .section-name {
  color: #000;
  text-align: left;
  width: 310px;
  padding-top: 26px;
  font-family: Manrope;
  font-size: 80px;
  line-height: 114%;
}

@font-face {
  font-family: Manrope;
  src: url("../Manrope-Light.55fef8b1.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("../Manrope-Regular.52ca3563.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("../Manrope-Medium.e596b2a0.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("../Manrope-Bold.6a1a9a4d.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=main.css.map */
