// .train-existing-ai {
//     position: absolute;
//     top: 292px;
//     left: 300px;
//     font-size: var(--regular-body-large-size);
//     line-height: 130%;
//     color: var(--color-black);
//     text-align: left;
//     display: flex;
//     align-items: center;
//     width: 259px;
// }
// .automate-reporting-and {
//     position: absolute;
//     top: 344px;
//     left: 881px;
//     font-size: var(--regular-body-large-size);
//     line-height: 130%;
//     color: var(--color-black);
//     text-align: left;
//     display: flex;
//     align-items: center;
//     width: 261px;
// }
.section-name {
    // position: absolute;
    // top: 136px;
    // // left: 208px;
    // // font-size: var(--display-x-large-size);
    // line-height: 114%;
    // display: inline-block;
    // color: var(--color-black);
    // text-align: left;
    // width: 310px;
}

.section-name1 {
    // position: absolute;
    // top: 1px;
    // // left: 20px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // width: 427px;
}

.tooltip-icon {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    // width: 12px;
    // height: 17.3px;
    // overflow: hidden;
}

.hero-section {

    .instruction {
        // position: absolute;
        // top: 93px;
        // // left: 213px;
        // width: 447px;
        // height: 17.3px;
        // text-align: left;
        // font-size: var(--regular-footer-size);
        // color: #fd0095;
        align-items: center;
        display: flex;
        width: max-content;
        padding-top: 93px;
        width: 100%;
        position: relative;
        height: 17.3px;
        text-align: left;
        font-size: 13px;
        // font-size: var(--regular-footer-size);
        color: #fd0095;
        font-family: Manrope;
        gap: 8;
        // font-family: var(--regular-footer);

        .tooltip-icon {
            width: 12px;
            height: 17.3px;
            overflow: hidden;
        }

        .section-name1 {
            line-height: 120%;
            display: flex;
            align-items: center;
            width: 427px;
        }
    }

    .section-name {
        padding-top: 26px;
        width: 310px;
        // position: relative; 
        font-size: 80px;
        // font-size: var(--display-x-large-size);
        line-height: 114%;
        // display: inline-block;
        font-family: Manrope;
        // font-family: var(--display-x-large);
        color: #000;
        text-align: left;
    }
}


.layer-2-icon {
    // position: absolute;
    // height: 65.74%;
    // width: 11.56%;
    // top: 25.83%;
    // right: 55.89%;
    // bottom: 8.43%;
    // left: 32.55%;
    // max-width: 100%;
    // overflow: hidden;
    // max-height: 100%;
}

.layer-2-icon1 {
    // position: absolute;
    // top: 231px;
    // left: 945px;
    // width: 273px;
    // height: 771px;
    // overflow: hidden;
}

.layer-2-icon2 {
    // position: absolute;
    // top: 392px;
    // left: 1254px;
    // width: 225px;
    // height: 610px;
    // overflow: hidden;
}

.layer-2-icon3 {
    // position: absolute;
    // top: 241px;
    // left: 1545px;
    // width: 285px;
    // height: 768px;
    // overflow: hidden;
}

.layer-2-icon4 {
    // position: absolute;
    // top: 227px;
    // left: 316px;
    // width: 300px;
    // height: 770px;
    // overflow: hidden;
}

.section-name2 {
    // position: absolute;
    // top: 871px;
    // left: 365px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 123px;
}

.section-name3 {
    // position: absolute;
    // top: 871px;
    // left: 670px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 123px;
}

.section-name4 {
    // position: absolute;
    // top: 871px;
    // left: 974px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 123px;
}

.section-name5 {
    // position: absolute;
    // top: 871px;
    // left: 1284px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 121px;
}

.section-name6 {
    // position: absolute;
    // top: 871px;
    // left: 1583px;
    // line-height: 120%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 123px;
}

.section-13 {
    // width: 100%;
    // position: relative;
    // background-color: var(--hotwire-brand-light-grey-rgb);
    // height: 1080px;
    // overflow: hidden;
    // flex-shrink: 0;
    // text-align: center;
    // font-size: var(--font-size-77xl);
    // color: var(--color-white);
    // font-family: var(--regular-footer);
}