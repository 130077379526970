@use "../mixins/" as *;

footer{ padding: 70px 0;
    @include max(991){ padding: 40px 0;}
    .txt{display: flex; align-items: center;
        @include max(767){flex-direction: column; align-items: flex-start;}
        p {
            font-size: 16px;
            display: inline-block;
            @include max(1199){font-size: 14px;}
            @include max(767){padding-bottom: 20px;}
        }
        ul{ display: flex; margin: 0px;
            @include max(575){display:block;}
            li{ list-style: none;     padding-left: 15px;
                @include max(767){padding-left: 0px; padding-right: 15px;}
                @include max(575){display: inline-block;}
                a {
                    color: #000;
                    font-size: 12px;
                    font-weight: 400;
                    text-decoration: underline;
                    display: block;
                    @include max(1199){font-size: 10px;}
                }
            }
        }
    }
    .social-icons{display: flex; justify-content: flex-end; align-items: center;
        @include max(991){ margin-top: 30px; justify-content: flex-start;}
        li{list-style: none;
            +li{margin-left: 20px;}
            svg{width: 20px; height: auto;
                path{fill: #fd0095; transition:all ease-out  250ms;}
            }
            a{display: block;
                &:hover{
                    svg{
                        path{fill: black;}
                    }
                }
            }
        }
    }
}