@use "../variables" as *;
@use "../mixins/" as *;

.codepen-scroll-eff {

    @keyframes roll {
        100% {
            transform: translateX(170px) rotate(360deg);
        }
    }

    @keyframes rollback {
        0% {
            transform: translateX(170px) rotate(360deg);
        }

        100% {
            transform: translateX(0px) rotate(0deg);
        }
    }

    .col-point {
        // margin: auto;
        // width: 333px;
        // height: 500px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        // width: max-content;
        position: absolute;
        // bottom: 20px;
        right: 20px;
        width: 50%;
        justify-content: flex-end;
        align-items: center;
        // gap: 60;
        // right: 0px;
        bottom: -8rem;
    }

    .wrapper {
        /* height: 90%;
    width: 100%; */
        overflow-y: auto;
    }


    /* Modals - End */

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 10px;
        height: 10px;
        width: 3px;
    }

    @keyframes rotation {
        0% {
            --gradient-angle: 0deg;
        }

        100% {
            --gradient-angle: 360deg;
        }
    }

    // @property --gradient-angle
    // {
    //     syntax: "<angle>";
    //     initial-value: 0deg;
    //     inherits: false;
    // }
    #div1 {
        /* background-color: blue; */
        width: fit-content;

    }

    #div2 {
        width: fit-content;
        background: url('../images/Golf-Ball-Hole.svg') no-repeat center;
        /* background-color: green; */
        min-width: 120px;
        min-height: 80px;
        border-radius: 50%;
        // position: absolute;
        // right: 20px;
        // bottom: 20px;
        // margin-left: 20px;
        margin-left: 70%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ball {
            background: url('../images/ball-2.svg') no-repeat center bottom;
            width: 85px;
            height: 60px;
        }
    }
}

.ball {
    /* margin: auto; */
    width: fit-content;
    background: url('../images/ball-1.svg') no-repeat center;
    /* background-color: green; */
    // min-width: 120px;
    // min-height: 80px;
    border-radius: 50%;
    // position: absolute;
    // right: 150px;
    // bottom: 20px;

    // border-radius: 50%;
    /* width: fit-content; */
    width: 45px;
    /* min-height: 81px; */
    // background-color: blue;
    height: 45px;
}
