.svg-mask {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    transition: stroke-dashoffset 2s ease-in-out;
}

.svg-mask-2 {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    transition: stroke-dashoffset 2s ease-in-out;
}


.svg-mask-lr {
    stroke-dasharray: 4000;
    stroke-dashoffset: 4000;
    transition: stroke-dashoffset 2s ease-in-out;
}

