// .card-scroll {

//     .head {
//         background: red;
//     }

//     .footer {
//         background: yellow;
//     }

//     .head,
//     .footer {
//         height: 100vh;
//     }

//     .pin-panel {
//         padding: 5rem 2rem;
//     }

//     .cards .card {
//         aspect-ratio: 1/1;
//         background: pink;
//     }

//     .cards .pin-spacer:nth-child(even) .card {
//         background: yellow;
//     }

//     .cards .card:not(:last-child) {
//         margin-bottom: 4rem;
//     }

//     figure {
//         padding: 0;
//         margin: 0;
//     }

//     .image img {
//         display: block;
//         margin: auto;
//     }

//     .body {
//         display: -webkit-box;
//         display: -webkit-flex;
//         display: -ms-flexbox;
//         display: flex;

//         gap: 2rem;
//     }

//     .pin-panel .image,
//     .pin-panel .cards {
//         flex-basis: calc(50% - 2rem + 1rem);
//     }

//     .footer {
//         position: relative;
//         z-index: 2;
//     }
// }

// .card-scroll-chp {
//     .col8 article .slider {
//         display: flex;
//         gap: 24px;
//         overflow-y: hidden;
//         overflow-x: scroll;
//         /* white-space: nowrap; */
//     }
//     .col8 article .slider .slide-wrap {
//         display: flex;
//         width: 297px;
//         height: 380px;
//         padding: 24px;
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 32px;
//         border-radius: 24px;
//         background: #F6E4EC;
//     }
//     .col8 article .slider .slide-wrap figure img {
//         max-width: 97px;
//         height: 96px;
//     }
    
// }

.card-scroll-chp-2 {

//  height: 2000px;
    // .col8 article .slider {
    //     display: flex;
    //     gap: 24px;
        overflow-y: hidden;
        overflow-x: hidden; /* Hide horizontal scrollbar */
    //     // white-space: nowrap;
    // }
    
    .scroll-on-to {
        // width: 2000px;
    }
    // .col8 article .slider .slide-wrap {
        // display: inline-flex;
        // width: 297px;
        // height: 380px;
        // padding: 24px;
        // flex-direction: column;
        // align-items: flex-start;
        // gap: 32px;
        // border-radius: 24px;
        // background: #F6E4EC;
    // }
    
    // .col8 article .slider .slide-wrap figure img {
    //     max-width: 97px;
    //     height: 96px;
    // }
    
    p {
        width: 210px;

    }
}