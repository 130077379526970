@use "../variables" as *;
@use "../mixins/" as *;

.m1 {
    position: relative;

    .left-side {
        position: absolute;

        @include max(1299) {
            left: -70px;
        }

        @include max(1199) {
            left: -150px;
        }

        @include max(991) {
            display: none;
        }

        figure {
            z-index: 2;
            position: relative;

            img {
                max-width: 400px;
                height: 558px;
            }
        }

        svg {
            position: absolute;
            top: -18px;
        }
    }



    .m1-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // height: 940px;
        gap: 206px;
        // height: 940px;
        height: 740px;

        @include max(991) {
            height: auto;
            gap: 0;
        }

        .row {
            .col12 {
                article {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    @include max(575) {
                        max-width: 280px;
                    }

                    h1 {
                        color: #000;
                        text-align: center;
                        font-family: $fontFamily;
                        font-size: 88px;
                        font-style: normal;
                        font-weight: $fw700;
                        line-height: 120%;

                        @include max(991) {
                            font-size: 39px;
                        }


                    }

                    h2 {
                        color: #000;
                        text-align: center;
                        font-family: Manrope;
                        font-size: 56px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 114%;
                        max-width: 606px;

                        @include max(991) {
                            font-size: 20px;
                            padding-top: 10px;
                        }

                        @include max(575) {
                            max-width: 240px;
                        }
                    }

                    p {
                        color: var(--Hotwire-Brand-Black-RGB, #000);
                        text-align: center;
                        font-family: Manrope;
                        font-size: 21.529px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        padding-top: 34px;
                        /* 25.834px */

                        @include max(991) {
                            font-size: 16px;
                            padding-top: 20px;
                        }

                        @include max(575) {
                            max-width: 230px;
                        }
                    }
                }
            }
        }

        .box-wrap {
            display: flex;
            justify-content: center;
            gap: 8px;

            @include max(1199) {
                gap: 15px;
            }

            @include max(991) {
                display: none;
            }

            a {
                color: #FD0095;
                font-family: Manrope;
                font-size: 23px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                height: 72px;
                padding: 10px 30px 8px 30px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border: none;
                background: #F6E4EC;
                text-decoration: none;
                display: flex;
                max-width: 465px;
                gap: 8px; 
                transition:all ease-in-out 250ms;
                @include max(1199) {
                    font-size: 19px; padding: 10px 15px 8px 15px;
                }
                &:hover{background: #FD0095; color: #fff;
                    svg{
                        path{fill: #fff;
                            &:nth-child(2){fill: #FD0095;}
                        }
                    }
                }
                svg{fill{transition:all ease-in-out 250ms;}}
            }

            @include max(767) {
                flex-direction: column;
            }
        }
    }

    .right-side {
        position: absolute;
        top: 0;
        right: 0;

        @include max(1299) {
            right: -70px;
        }

        @include max(1199) {
            right: -150px;
        }

        @include max(991) {
            display: none;
        }

        figure {
            position: relative;
            z-index: 2;

            @include max(767) {
                padding-left: 30px;
                // padding-right: 30px;
            }

            img {
                max-width: 466px;
                height: 496px;

                @include max(767) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        svg {
            position: absolute;
            top: 0;
            right: 0;

            @include max(767) {
                max-width: 327px;
                height: 665px;
            }
        }
    }

    .m1-container {
        @include max(991) {
            padding-bottom: 0;
        }

        @include max(575) {
            padding-bottom: 0;
        }
    }

    .main-img {
        display: none;
        text-align: right;
        width: 100%;

        img {
            display: inline-block;
            max-width: 80%;
        }

        @include max(991) {
            display: inline-block;
        }

        @include max(575) {}
    }

    .tip {
        font-size: 13px;
        font-weight: 400;
        color: #FD0095;
        background: url(../images/bulb.svg) no-repeat left center / 12px;
        padding: 2px 0 2px 20px;
        margin: 0 0 10px 0;
    }

    .btm-selection {
        padding: 20px 20px 20px 20px;
        display: none;

        @include max(991) {
            display: block;
        }

        ul {
            display: flex;

            li {
                flex: 0 0 33.33333%;
                list-style: none;

                a {
                    font-size: 12px;
                    color: #FD0095;
                    font-weight: 700;
                    text-decoration: none;
                    outline: 0 !important;

                    &:hover {
                        border-bottom: 2px solid #FD0095;
                    }
                }
            }
        }
    }
}


.m2 {
    background-color: #FD0095;
    position: relative;
    // padding: 80px 0;

    .power-container {
        padding-top: 80px;
        padding-bottom: 80px;

        .power-row {
            align-items: center;

            .col6 {
                article {
                    p {
                        color: #FFF;
                        font-family: Manrope;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;

                        @include max(767) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .right-side-col {
                @include max(991) {
                    display: none;
                }

                @include max(767) {
                    padding-top: 93px;
                }

                article {
                    figure {
                        margin-top: calc(18% - 25px);
                        margin-left: calc(21% - 25px);

                        img {
                            max-width: 547px;
                            height: 731px;

                            @include max(767) {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .main-img {
            display: none;

            @include max(991) {
                display: block;
                margin: 40px 0 0 -15px;
                max-width: 350px;
            }

            @include max(575) {
                max-width: 270px;
            }
        }
    }

    .stroke {
        position: absolute;
        right: 0;
        // z-index: 1;

        @include max(1299) {
            right: -70px;
        }

        @include max(1199) {
            right: -150px;
        }

        @include max(991) {
            display: none;
        }

        @include max(767) {
            // display: none;
            max-width: 820px;
            height: 682px;
            bottom: -23px;
            left: -551px;
            transform: scaleX(-1);
        }
    }
}

.m3 {
    position: relative;
    // display: none;

    .left-side-svg {
        position: absolute;
        // display: none;

        .svg-top {
            height: 430px;
            display: block;
            top: -40px;
            position: relative;
            left: -100px;
        }

        @include max(991) {
            display: none;
        }

        .hand-svg {
            position: relative;
            // display: none !important;
            transition: all 2s ease-in-out;

            .inner-svg {
                position: absolute;
                top: calc(10vh - 194px);
                left: calc(10vw + 90px);
            }

            // &.active {
            //     display: block;
            // }
        }

        .hand-svg-card {
            position: relative;
            display: none;
            transition: display 2s ease-in-out;
            margin-top: -100px;
            top: -150px;
            left: -60px;

            img {
                max-width: 400px;
                // height: 400px;
            }

        }

        .hand-svg-card-1 {
            // display: block !important;
        }

        // .hand-svg-card-3 {

        // display: block;
        // margin-top: -140px;
        // img {
        //     max-width: 500px;
        //     height: auto;
        // }
        // }
        // .hand-svg-card-4 {
        // display: block;
        // top: -140px;
        // left: -170px;
        // display: block;
        // img {
        //     width: 400px;
        //     height: 400px;
        // }
        // }
    }

    // .svg-hold {
    //     position: relative;
    //     svg {
    //         position: absolute;

    //     }
    // }

    // .svg-hold {
    //     height: 900px;
    //     position: absolute;
    //     // position: relative;
    //     // top: -600px;
    //     top: 0;
    //     right: 0;
    //     z-index: -1;
    // }
    // .svg-hold svg {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    // }

    .container {
        padding: 100px 0;

        @include max(991) {
            padding: 50px 0 0 0;
        }

        .row {
            .col4 {}

            .playing-cards {
                display: flex;
                flex-direction: column;
                gap: 50px;

                h3 {
                    // padding-left: calc(50px + 2vw);
                    color: #000;
                    font-family: Manrope;
                    font-size: 49px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    max-width: 680px;
                    text-align: left;
                    margin-left: calc(10% + 20px);

                    @include max(991) {
                        margin: 0 0 0 20px;
                    }

                    @include max(767) {
                        font-size: 25px;
                    }
                }

                .cards-wrap {
                    padding: 40px 0 20px 0;
                    top: 50px;
                    display: flex;
                    // display: none;
                    // border: 2px solid red;
                    position: relative;
                    left: 53px;
                    cursor: pointer;

                    @include max(991) {
                        display: none;
                    }

                    @include max(767) {
                        display: none;
                    }

                    .playing-card-uno {
                        width: 218px;
                        height: 294px;
                        // transition: z-index 2s ease-in-out;
                        transition: all 0.5s ease-in-out;

                        h2 {
                            padding-top: calc(30% - 50px);
                            color: #FFF;
                            font-family: Manrope;
                            font-size: 59px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            /* 86.4px */
                        }

                        p {
                            color: #FFF;
                            font-family: Manrope;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            // width: 153px;
                            word-break: break-word;
                        }
                    }

                    .card-wrap-1 {
                        background: #62165c;
                        border-radius: 24px;
                        flex-direction: column;
                        gap: 24px;
                        padding: 24px;
                        display: flex;
                        transform: rotate(-30deg) scale(1);
                        // transition: all 2s ease-in-out;

                        &:hover {
                            z-index: 2;
                            transform: rotate(-30deg)scale(1.2);
                        }
                    }

                    .card-wrap-2 {
                        border-radius: 24px;
                        background: #4A1F69;
                        display: flex;
                        padding: 24px;
                        flex-direction: column;
                        gap: 24px;
                        // position: relative;
                        // top: -53px;
                        // left: -100px;
                        // transform: rotate(-18.489deg);
                        // transform: rotate(-17.489deg);
                        transform: rotate(-17.489deg) scale(1);
                        left: 112px;
                        position: absolute;
                        top: -19px;


                        &:hover {
                            z-index: 2;
                            transform: rotate(-17.489deg) scale(1.2);
                        }
                    }

                    .card-wrap-3 {
                        border-radius: 24px;
                        background: #1C3075;
                        display: flex;
                        padding: 24px;
                        flex-direction: column;
                        gap: 24px;
                        // transform: rotate(1.243deg);
                        // position: relative;
                        // left: -212px;
                        // top: -84px;
                        // transform: rotate(4.243deg);
                        position: absolute;
                        top: -44px;
                        left: 219px;
                        transform: rotate(4.243deg) scale(1);

                        &:hover {
                            z-index: 2;
                            transform: rotate(4.243deg) scale(1.2);

                        }
                    }

                    .card-wrap-4 {
                        border-radius: 24px;
                        background: #B50057;
                        display: flex;
                        padding: 24px;
                        flex-direction: column;
                        gap: 24px;
                        position: absolute;
                        top: -32px;
                        right: 231px;
                        transform: rotate(19.227deg);


                        &:hover {
                            z-index: 2;
                            transform: rotate(19.227deg) scale(1.2);

                        }
                    }

                    .card-wrap-5 {
                        border-radius: 24px;
                        background: #FD0095;
                        display: flex;
                        padding: 24px;
                        flex-direction: column;
                        gap: 24px;
                        position: absolute;
                        top: 20px;
                        right: 118px;
                        transform: rotate(38.208deg);


                        &:hover {
                            z-index: 2;
                            transform: rotate(38.208deg) scale(1.2);
                        }
                    }
                }

                .bottom-text {
                    padding-top: 27px;

                    @include max(991) {
                        display: none;
                    }

                    p {
                        text-align: right;
                    }
                }

                // .mobile-playing-cards{
                //     .playing-cards-slider{

                //         .playing-card-uno {
                //             width: 218px;
                //             height: 294px;

                //             h2 {
                //                 padding-top: calc(30% - 50px);
                //                 color: #FFF;
                //                 font-family: Manrope;
                //                 font-size: 59px;
                //                 font-style: normal;
                //                 font-weight: 700;
                //                 line-height: 120%;
                //                 /* 86.4px */
                //             }

                //             p {
                //                 color: #FFF;
                //                 font-family: Manrope;
                //                 font-size: 23px;
                //                 font-style: normal;
                //                 font-weight: 400;
                //                 line-height: 120%;
                //                 // width: 153px;
                //                 word-break: break-word;
                //             }
                //         }

                //     }
                // }
            }
        }

    }

    .card-wrap-1:hover>.hand-svg-1 {
        display: block;
    }
}


.mobile-playing-cards {
    display: none;

    .bottom-text {
        display: none;

        @include max(991) {
            display: block;
            padding: 20px 0 0 20px;
        }
    }

    .slick-slide {
        padding: 0 20px;
    }

    @include max(991) {
        display: block;
    }

    .playing-cards-slider {
        display: flex;
        gap: 18px;
        padding: 36px 0 0px 0;

        .playing-card-uno {
            max-width: 100%;
            height: 215px;

            h2 {
                padding-top: 0;
                color: #FFF;
                font-family: Manrope;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 86.4px */
            }

            p {
                color: #FFF;
                font-family: Manrope;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                // width: 153px;
                word-break: break-word;
            }
        }

        .card-wrap-1 {
            background: #62165c;
            border-radius: 24px;
            flex-direction: column;
            gap: 15px;
            padding: 18px;
            display: flex;
            // transform: rotate(-30deg);

            &:hover {
                z-index: 2;
            }
        }

        .card-wrap-2 {
            border-radius: 24px;
            background: #4A1F69;
            display: flex;
            padding: 18px;
            flex-direction: column;
            gap: 15px;
            // position: relative;
            // top: -53px;
            // left: -100px;
            // transform: rotate(-18.489deg);
            // transform: rotate(-17.489deg);
            // left: 112px;
            // position: absolute;
            // top: -19px;


            &:hover {
                z-index: 2;
            }
        }

        .card-wrap-3 {
            border-radius: 24px;
            background: #1C3075;
            display: flex;
            padding: 18px;
            flex-direction: column;
            gap: 15px;
            // transform: rotate(1.243deg);
            // position: relative;
            // left: -212px;
            // top: -84px;
            // transform: rotate(4.243deg);
            // position: absolute;
            // top: -44px;
            // left: 219px;
            // transform: rotate(4.243deg);


            &:hover {
                z-index: 2;
            }
        }

        .card-wrap-4 {
            border-radius: 24px;
            background: #B50057;
            display: flex;
            padding: 18px;
            flex-direction: column;
            gap: 15px;
            // position: absolute;
            // top: -32px;
            // right: 231px;
            // transform: rotate(19.227deg);


            &:hover {
                z-index: 2;
            }
        }

        .card-wrap-5 {
            border-radius: 24px;
            background: #FD0095;
            display: flex;
            padding: 18px;
            flex-direction: column;
            gap: 15px;
            // position: absolute;
            // top: 20px;
            // right: 118px;
            // transform: rotate(38.208deg);


            &:hover {
                z-index: 2;
            }
        }



    }
}

.m4 {
    padding: 0 0 100px 0;
    position: relative;
    overflow: hidden;

    @include max(991) {
        padding: 100px 0 50px 0;
    }

    @include max(767){
        padding: 40px 0 100px 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .min-height {
        min-height: 500px;

        @include max(991) {
            display: block;
            min-height: 380px;
        }

        @include max(767) {
            min-height: 410px;
        }

        @include max(575) {
            min-height: 530px;
        }
    }

    .analyse-container {

        .aaa-row {
            gap: 30px;
            margin: 0;

            @include max(767) {
                flex-direction: column;
                gap: 18px;
            }

            .col5 {
                article {
                    max-width: 468px;

                    @include max(991) {
                        max-width: 100%;
                        padding-bottom: 40px;
                    }

                    h2 {
                        // color: #000;
                        // font-family: Manrope;
                        // font-size: 88px;
                        // font-style: normal;
                        // font-weight: 700;
                        // line-height: 120%;
                        // /* 105.6px */

                        // @include max(767) {
                        //     font-size: 49px;
                        // }

                        // span {
                        //     color: #FD0095;
                        //     font-family: Manrope;
                        //     font-size: 88px;
                        //     font-style: normal;
                        //     font-weight: 700;
                        //     line-height: 120%;

                        //     @include max(767) {
                        //         font-size: 49px;
                        //     }
                        // }


                        .analyse-article {
                            color: #000;
                            font-family: Manrope;
                            font-size: 88px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            display: block;
                            cursor: pointer;
                            /* 105.6px */

                            &:hover {
                                color: #FD0095 !important;
                            }

                            &.active {
                                color: #FD0095;
                                font-family: Manrope;
                                font-size: 88px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 120%;
                            }


                            @include max(991) {
                                font-size: 49px !important;
                            }
                        }

                        // .analyse-article.active {
                        //     color: #FD0095;
                        //     font-family: Manrope;
                        //     font-size: 88px;
                        //     font-style: normal;
                        //     font-weight: 700;
                        //     line-height: 120%;

                        //     @include max(767) {
                        //         font-size: 49px;
                        //     }
                        // }
                    }
                }
            }

            // .col7 {
            //     article {
            //         max-width: 560px;


            //         @include max(767) {
            //             max-width: 100%;
            //         }

            //         p {
            //             color: #000;

            //             /* Regular/Body Large */
            //             font-family: Manrope;
            //             font-size: 20px;
            //             font-style: normal;
            //             font-weight: 700;
            //             line-height: 135%;
            //             /* 26px */

            //             @include max(767) {
            //                 font-size: 20px;
            //                 font-style: normal;
            //                 font-weight: 400;
            //             }
            //         }
            //     }

            //     .vector-img {
            //         display: flex;
            //         justify-content: flex-end;
            //         padding-top: 50px;

            //         @include max(767) {
            //             display: none;
            //         }

            //         figure {
            //             img {
            //                 max-width: 248px;
            //                 height: 258px;
            //             }
            //         }
            //     }
            // }

            .analyse-article-details {
                display: none;

                @include max(991) {
                    flex: 100%;
                }

                &.active {
                    display: block;
                }

                article {
                    max-width: 560px;


                    @include max(991) {
                        max-width: 100%;
                    }

                    p {
                        color: #000;

                        font-family: Manrope;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;

                        @include max(767) {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                }

                .vector-img {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 50px;

                    @include max(991) {
                        display: none;
                    }

                    figure {
                        img {
                            max-width: 248px;
                            height: 258px;
                        }
                    }
                }
            }

            .analyse-col {
                display: flex;
                gap: 22px;
                justify-content: space-between;
                width: 100%;
                align-items: center;

                @include max(767){
                    flex-direction: column;
                    align-items: flex-start;
                }

                .heading-wrap {

                    max-width: 452px;

                    h2 {
                        color: #FD0095;
                        font-family: Manrope;
                        font-size: 88px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;

                        @include max(767){
                            font-size: 49px;
                        }
                    }
                }


                    .content-wrap {
                        max-width: 686px;
                        p {
                            color: #000;
                            font-family: Manrope;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 130%; 

                            @include max(767){
                                font-size: 20px;
                            }
                        }
                    }
                
            }
        }
    }

    .full-stroke {
        // position: absolute;
        // position: relative;
        // top: calc(-12% - 107px);
        // right: calc(2% - 85px);
        // z-index: -1;


        // svg {
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        // }

        // display: none;

        // .svg-mask {
        //     // stroke-dasharray: 0;
        //     // stroke-dashoffset: 500;
        //     // animation: dash 5s linear alternate infinite;
        // }
        // .svg-mask {
        //     stroke-dasharray: 1800;
        //     stroke-dashoffset: 0;
        //     transition: stroke-dashoffset 2s ease-in-out;
        // }

        @include max(767) {
            // display: none;
        }
    }

    .svg-stroke {
        // height: 900px;
        // // position: absolute;
        // // position: relative;
        // // top: -600px;
        // top: 0;
        // right: 0;
        // z-index: -1;

        
        @include max(1599) {
            top: 90px;
        }
        
        @include max(1399) {
            top: 124px;
        }
        
        @include max(1199) {
            top: 110px;
            right: 0px;
        }
        
        @include max(991) {
            display: none;
        }
    }
    .container{

        .infographic{
            justify-content: flex-end;
            display: flex;
            margin-top: -55px;
            figure{
                img{
                    max-width: 602px;
                    height: 429px;

                    @include max(991){
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .svg-hold svg {
        min-width: 119%;
    position: absolute;
    top: 253px;
    right: 0;
    }

    .container {
        
        // padding-top: 150px;
        padding-top: 63px;
        // padding-top: 50px;
        // padding-left: calc(86px + 2vw);
        // overflow: hidden;

        @include max(991) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @include max(767) {
            padding-top: 0px;
        }

        h3 {
            color: #000;
            /* Regular/Display Small */
            font-family: Manrope;
            font-size: 39px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 46.8px */
            // max-width: 295px;
            padding-bottom: 30px;

            @include max(991) {
                max-width: 185px;
                padding-bottom: 20px;
                font-size: 25px;
            }

        }
        .cards-row {
            justify-content: center;
            gap: 30px;

            @include max(767) {
                justify-content: center;
            }


            .card-col {
                max-width: 258px;

                @include max(767){
                    max-width: 301px;
                }
                

                article {

                    figure {
                        @include max(991) {
                            // display: none;
                        }

                        img {
                            max-width: 300px;
                            height: 330px;

                            @include max(767){
                                max-width: 100%;
                            }
                        }
                    }
                }

                .slide-wrap {

                    display: flex;
                    // width: 297px;
                    padding: 24px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 32px;
                    border-radius: 24px;
                    max-width: 269px;
                    height: 380px;
                    background: #F6E4EC;

                    @include max(767){
                        width: 301px !important; 
                        max-width: 301px;
                     }

                    figure {
                        img {
                            max-width: 97px;
                            height: 96px;
                        }
                    }

                    p {
                        width: 210px;
                    }

                }
            }


            .img-card-col {
                @include max(767) {
                    display: none;
                }
            }

            .col3 {
                article {
                    .slider-neww {
                        display: flex;
                        gap: 24px;

                        // overflow-y: hidden;
                        // overflow-x: auto;
                        // white-space: nowrap;
                        // width: auto;
                        // &::-webkit-scrollbar {
                        //     display: none;
                        // }
                        // -ms-overflow-style: none;  /* IE and Edge */
                        // scrollbar-width: none; 


                        .slide-wrap {

                            display: flex;
                            width: 297px;
                            height: 380px;
                            padding: 24px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 32px;
                            border-radius: 24px;
                            background: #F6E4EC;

                            figure {
                                img {
                                    max-width: 97px;
                                    height: 96px;
                                }
                            }

                            p {
                                width: 210px;
                            }

                        }

                        .slick-track {
                            display: flex;
                            gap: 10px;
                        }
                    }
                }
            }
        }
    }
}

.step-1 {
    @include max(991) {
        display: none;
    }
}

.m6 {
    padding: 150px 0 190px 0;

    .container {
        .row {
            .col5 {
                article {
                    position: relative;

                    // padding-top: 114px;
                    .left-heading-wrap {
                        display: flex;
                        flex-direction: column;
                        gap: 26px;

                        h2 {
                            color: #000;
                            font-family: Manrope;
                            font-size: 80px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 114%;
                            /* 91.2px */
                            max-width: 427px;
                        }
                    }

                    .magnifying {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        figure {
                            img {
                                width: 236px;
                                height: 340px;
                            }
                        }
                    }
                }
            }

            .col7 {
                article {
                    // .sticky-notes {
                    //     display: flex;
                    //     gap: 16px;
                    //     flex-wrap: wrap;

                    //     @include max(767) {
                    //         display: none;
                    //     }

                    //     .note {
                    //         figure {
                    //             img {
                    //                 // background-color: rgb(0, 87, 32);
                    //                 max-width: 149px;
                    //                 height: 149px;
                    //                 @include max(1199) {max-width: 124px; height: 124px;}
                    //             }
                    //         }
                    //     }
                    // }

                }
            }
        }

        .sticky-last {
            padding-top: 40px;

            @include max(767) {
                display: none;
            }
        }



    }

    .loupe {
        display: none;
        position: absolute;
        width: 260px;
        height: 260px;
        // box-shadow: 5px 5px 12px black;
        cursor: none;
        overflow: hidden;
        border-radius: 50%;
        // background: white;
        // transition: all .5s ease; 
    }

    .loupe-svg {
        display: none;
        position: absolute;
        width: 200px;
        height: 200px;
        z-index: 1;
        cursor: none;

    }

    .loupe img {
        position: absolute;
        right: 0;

        max-width: 800%;
        max-height: 800%;
    }

    .sticky-notes {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .note {
            figure {
                img {

                    // background-color: rgb(0, 87, 32);
                    // max-width: 149px;
                    // height: 149px;
                    // max-width: auto;
                    @include max(1199) {
                        max-width: 119px;
                        height: 119px;
                    }
                }
            }
        }
    }
}

.m3-new {
    padding: 90px 0;

    @include max(767) {
        padding: 40px 0;
    }

    .container {
        .heading-wrap {
            text-align: center;

            @include max(767) {
                text-align: left;
            }

            h2 {
                font-family: Manrope;
                font-size: 49px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;

                // @include max(767) {
                //     font-size: 25px;
                // }
            }

            span {
                color: #000;
                text-align: center;

                /* Regular/Footer */
                font-family: Manrope;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 15.6px */
            }
        }
    }

    .m3-new-row {
        justify-content: space-evenly;
        gap: 30px;
        padding-top: 85px;

        @include max(767) {
            padding-top: 36px;
        }

        .hand {
            article {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h3 {
                    font-family: Manrope;
                    font-size: 49px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    color: #FD0095;
                }

                span {
                    color: #000;
                    // max-width: 183px;
                    max-width: 164px;
                    text-align: center;
                    /* Regular/Body Large */
                    font-family: Manrope;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                }
            }

        }

        .targeting {
            background-image: url(/src/images/targeting.jpg);
            max-width: 323px;
            height: 306px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;


        }

        .personalisation {
            background-image: url(/src/images/personalisation.jpg);
            max-width: 323px;
            height: 306px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .orchestration {
            background-image: url(/src/images/orchestration-new.jpg);
            max-width: 323px;
            height: 306px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .conversation {
            background-image: url(/src/images/conversation-automation.png);
            max-width: 323px;
            height: 306px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .audience{
            background-image: url(/src/images/audience-insights.png);
            max-width: 323px;
            height: 306px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}