@use "../variables" as *;
@use "../mixins/" as *;

.fd-contact-us{position: fixed; top: 0px; right: -100px; bottom: 0; margin: auto; background: #F6E4EC; display: flex; align-items: center; justify-content: center; font-size: 18px; color: #FD0095; font-weight: 700; width: 245px; height: 50px; padding: 10px 10px; transform: rotate(-90deg); gap: 10px; z-index: 9; cursor: pointer;
    @include max(991) { width: 200px; right: -75px;}
    @include max(767) {font-size: 15px; height: 43px; width: 150px;  right: -55px}
    svg{transform: rotate(90deg);
        @include max(767) {width: 12px; height: 12px;}
    }
}
.form-wrap{ position: absolute; z-index: 99;  left: 0px; margin: auto; transition:all ease-in-out 250ms; width: 100%; padding: 20px 0;
    &:after{ width: 100%; height: 100%; background: rgba(0,0,0,.6); content: ''; position: fixed; inset: 0; z-index: -1; }
    
    .content{ background: #FFF; max-width: 720px; left: 0px; right: 0px; margin: auto;
        @include max(767) {max-width: 550px;}
        @include max(575) { max-width: 90%;}
        .head{ display: flex; padding: 30px 60px 0 60px; background: #F6E4EC; position:relative ; overflow: hidden;
            @include max(767) { padding: 20px 20px 0 20px;}
            @include max(576) {padding:15px 15px 0}
            h2{font-size: 44px; line-height: 1.2; color: #FD0095; font-weight: 700;
                @include max(767) {font-size: 35px;}
                @include max(575) {font-size: 20px;}
            }
            svg{width: 201px;flex: 0 0 200px; margin: 0 0 -15px 0;
                @include max(767) {width: 140px; height: 120px; flex: 0 0 140px;}
                @include max(575) {width: 40px; height: 80px; flex: 0 0 80px;}
            }
            .close{position: absolute; right: 20px; top: 30px; cursor: pointer; flex: auto !important; width: 40px; height: 40px;
                @include max(767) { width: 30px; height: 30px; right: 10px;}
                @include max(575) {width: 20px; height: 20px; right: 5px; top: 25px;}
            }
        }
        .forms{ padding: 40px 60px; background: #FFF;
            @include max(767) {padding: 30px 20px;}
            @include max(575) {padding: 20px 15px;}
            h3{font-size: 24px; font-weight: 500; line-height: 1.2; color: #FD0095; padding: 0 0 30px 0;
                @include max(575) {font-size: 20px; padding: 0 0 20px;}
            }
            .input{ margin-bottom: 25px; display: block; width: 100%; border: 0px; border-bottom: 1px solid #000; font-size: 18px; color: #000; font-weight: 500; font-family: Manrope; outline: 0 !important; padding: 10px 0;
                @include max(575) {font-size: 16px; margin-bottom: 15px;}
            }
            select{ margin-bottom: 25px; display: block; width: 100%; border: 0px; border-bottom: 1px solid #000; font-size: 18px; color: #000; font-weight: 500; font-family: Manrope; outline: 0 !important; padding: 10px 0;
                @include max(575) {font-size: 16px; margin-bottom: 15px;}
            }
            .privcy{ display: flex; gap: 10px; padding: 0 0 20px 0;
                .check{border: 1px solid #000; width: 20px; height: 20px;}
                label{ max-width: 470px; font-size: 16px; color: #000; line-height: 1.2; font-weight: 300; font-family: Manrope;
                    @include max(575) {font-size: 14px;}
                    a{color: #FD0095; text-decoration: none;
                        @include max(575) {font-size: 14px;}
                        &:hover{color: #000;}
                    }
                }
            }
            .btn{position: relative; display: flex;
                .submit{ cursor: pointer; background: #FD0095; padding:10px 60px 12px 60px; border: 0px; outline: 0; font-size: 18px; font-weight: 700; color: #FFF; font-family: Manrope, sans-serif;
                    @include max(575) {font-size: 16px;}
                }
                svg{margin: 16px 0 0 -50px;
                    @include max(575) {width: 12px; height: 12px;}
                }
            }
        }
    }
}
.clickpopup{height: auto !important; background-color: #fff; 
    .form-wrap{ opacity: 1 !important; visibility: visible !important; pointer-events: all !important; transition:all ease-in-out 250ms}
    
    body{ height: auto !important; background-color: #fff;}
}

.code-search-wrap{background: #FD0095; padding:150px 0 0 0; position: relative;
    @include max(991) {background-size: 840px; padding: 100px 0 60px 0;}
    @include max(767) {background-size: 610px; padding: 100px 0 60px 0;}
    @include max(575) {background-size: 420px; padding: 100px 0 60px 0;}
    h2{font-size: 80px; font-weight: 700; line-height: 1.2; text-align: center; color: #FFF; padding-bottom: 30px;
        @include max(991) {font-size: 56px;}
        @include max(767) {font-size: 31px; text-align: left; }
    }
    .reimagine-stroke{
        @include max(991) {display: none;}
        svg{position: absolute; left: 0; right: 0; margin: auto; top: 0;}
         
    }
    .crossing-img-wrap{ position: relative; z-index: 1; padding-top: 150px;
       img{display: block; margin: 0 auto;}
       .desk{
        @include max(991) {display: none;}
       }@include max(991) {display: none;}
    }
    .mob{ display: none; width: 100%;
        @include max(991) {display: block;}
    }
}

.highlight-wrap{background: #F3F3F3; 
    .txt{padding: 80px 0 80px 0;
        @include max(991) { padding: 50px 0;}
    }
    h2{font-size: 49px; font-weight: 700; line-height: 1.2; color: #FD0095; padding: 0 0 20px 0;
        @include max(991) {font-size: 42px;}
        @include max(767) {font-size: 25px; padding: 0 0 10px;}
    }
    p{font-size: 23px; font-weight: 400; line-height: 1.4; color: #000; padding: 0 0 0px 0;
        @include max(991) {font-size:19px;}
        @include max(767) {font-size: 16px;}
    }
    .img{height: 100%; position: relative;
        @include max(991) {}
        img{position: absolute; right: -15px; bottom: 0px;
            @include max(991) {position: inherit; display: block; margin: auto; right: 0; max-width: 300px; }
            @include max(767){max-width: 190px;}
            @include max(767){max-width: 140px;}
        }
    }
} 

.ethical{
    padding: 0 0 50px;

.ethical-wrap{  position: relative; z-index: 1; padding: 0 0 150px 0;
    @include max(1299) {padding: 0 0 190px;}
    @include max(991) { padding: 0 0 0px;}
    .ethical-m0b-img{display: none;
        @include max(991) {display: block;}
        img{width: 100%;}
    }
    .ethical-stroke{
        // position: absolute;
        bottom:45px; 
        // margin: auto; 
        // right: 0;
        @include max(1399) {bottom: 5px;}
        @include max(991) { display: none;}
        svg{
            position: absolute;
            bottom: -11px;
            right: 0; 
            path{
                @include max(1399) {stroke-width: 10px;}
            }
        }
    }
    .stick-men{
        position: absolute;
        right: 0; bottom: 243px;
        @include max(1399) {bottom: 211px;}
        @include max(991) { display: none;}
        figure{
            img{
                max-width: 450px;
                @include max(1399) {max-width: 340px;}
            }
        }
    }
    .txt{margin: 0 -160px 0 0;
        @include max(991) {margin: 0;}
    }
    // &:after{ position: absolute; height: 100%; max-width: 607px; content: ''; background: url(../images/stick-guy.png) no-repeat;
    //     @include max(1199) {background-size: 1010px;}
    //     @include max(991) {bottom: 30px; background-size: 770px;}
    //     @include max(767) {background-size: 580px;}
    //     @include max(575) {background-size: 330px;}
    // }    
    .txt{padding:0 0 50px 0;}
    h2{font-size: 80px; font-weight: 700; line-height: 1.2; color: #000; padding: 0 0 20px 0;
        @include max(1199) {font-size: 68px;}
        @include max(991) {font-size: 55px;}
        @include max(767) {font-size: 25px;}
    }
    p{font-size: 25px; font-weight: 400; line-height: 1.4; color: #000; padding-right:120px;
        @include max(1199) {font-size: 22px;  }
        @include max(991) { padding-right: 0;}
        @include max(767) {font-size: 16px;}
        +p{padding-top:30px;}
    }
}
}

.info-share-wrap{ padding-top: 100px; padding-bottom: 100px;
    @include max(991) { padding-top: 50px; padding-bottom: 50px; }
    .txt{max-width: 340px; float: right;
        @include max(991) {float: none; max-width: 100%;}
    }
    h2{font-size: 25px; font-weight: 700; line-height: 1.3; color: #000; padding: 0 0 10PX 0;
        @include max(575) {font-size: 16px;}
    }
    p{font-size: 20px; font-weight: 400; line-height: 1.4; color: #000; padding: 0 0 0px 0;
        @include max(575) {font-size: 16px;}
    }
    .video{ width: 100%; max-width: 680px; position: relative;
        @include max(991) {margin: 0 0 20px 0;}
        img{display: block;}
        svg{ width: 111px; height: 111px; position: absolute; inset: 0; margin: auto;
            @include max(991) { width: 80px; height: 80px;}
            @include max(991) { width: 50px; height: 50px;}
        }
    }
    &.order-change{
        .one{order: 2;
            @include max(991) {order: 1;}
        }
        .two{order: 1;
            @include max(991) {order: 2;}
        }
        .video{float: right;
            @include max(991) {float: none;}
        }
        .txt{float: left;
            @include max(991) {float: none;}
        }
    }
}

.full-content{background: #F6E4EC; padding: 24px; max-width: 680px; margin: 30px 0 0 0; 
    @include max(991) {display: none; font-size: 23px;}
    @include max(575) { padding: 15px; font-size: 16px;}
    svg{ margin: 15px 0 0px 0;
        @include max(767) {width: 14px; margin: 11px 0 0 0;}
        @include max(575) { font-size: 20px;}
    }
    &.mob{display: none; max-width: 100%;
        @include max(991) {display: block;}
    }
    .head{display: flex; justify-content: space-between; font-size: 25px; font-weight: 400; color: #FD0095; cursor: pointer;
        @include max(991) {
            font-size: 22px;
        }
    }
    .para{ padding: 15px 0 0 0;
        p{font-size: 20px; font-weight: 400; line-height: 1.4; color: #000; padding: 0 0 15px 0;
            @include max(991) { font-size: 16px;}
        }
        ul{ margin: 0 0 0 20px;
            li{font-size: 20px; font-weight: 400; line-height: 1.4; color: #000; list-style: number; padding: 0 0 15px 0;
                @include max(991) { font-size: 16px;}
                ol{ margin: 0 0 0 20px;
                    li{padding:5px 0 0 0; list-style: lower-alpha;}
                }
            }
        }
    }
}

.help-wrap{ text-align: center; background: #FD0095; position: relative; background-repeat: no-repeat;  padding: 70px 0 80px 0;
    @include max(991) {background-size: 940px; background-position: center 80px; padding:60px 0 60px 0;}
    @include max(767) {background-size: 740px; padding: 60px 0 60px;}
    @include max(575) {background-size: 430px; padding: 60px 0 60px; background-position: center 50px;}
    .ai-stroke{
        position: absolute; left: 0; right: 0; margin: auto;
        @include max(991) { display: none;}

        // svg {
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        // }
    }
    .main-img{display: none; margin: 20px 0;
        @include max(991) {display: block;}
        img{width: auto; height: auto; display: block;}
    }
    figure{
        img{ 
            max-width: 760px;
            height: 452px;

            @include max(767){
                max-width: 100%;
                height: auto;
            }
        }
        @include max(991) { display: none;}
    }
    h2{font-size: 80px; font-weight: 700; line-height: 1.2; color: #FFF; padding: 0 0 20px 0;
        @include max(991) {font-size: 56px; }
        @include max(767) {font-size: 31px; text-align: left; }
    }
    p{font-size: 31px; font-weight: 400; line-height: 1.2; color: #FFF; padding: 0 0 50px 0;
        @include max(991) {font-size: 22px; padding-bottom: 20px;}
        @include max(767) { text-align: left; font-size: 16px;}
    }
    .btn{
        @include max(767) {text-align: left;}
        a{display: inline-flex; padding: 10px 30px 8px 30px; align-items: center;  background: #FFF; gap: 8px; font-size: 18px; color: #FD0095; font-weight: 700; text-decoration: none; cursor: pointer;
            svg{ margin: 0 0 -1px 0;}
        }
    }
    &.second{ background-position: center 80px; padding:130px 0 80px 0;
        @include max(991) { background-size: 780px; background-position: center 80px; padding:100px 0 60px;}
        @include max(767) {background-size: 580px; padding: 100px 0 60px; background-position: center 50px;}
        @include max(575) {background-size: 430px; padding: 100px 0 60px; }
        .main-img{display: none;
            @include max(991) {display: block; width: 100%; margin: 50px 0  0;}
        }
        .second-stroke{
            position: absolute;
            top: 150px;
            left: 0px; right: 0; margin: auto;
            @include max(991){ display: none;}
        }
        .txt{
            display: flex;
    flex-direction: column;
    gap: 70px;
            figure{ @include max(991){ display: none;}
                img{
                    max-width: 874px;
                    height: 525px;
    
                    @include max(767){
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            h2{font-size: 88px;
                @include max(991) {font-size: 56px; }
                @include max(767) {font-size: 31px; text-align: left;  max-width: 300px;}
            }
            p{padding: 0; max-width: 920px; margin: 0 auto; font-size: 25px;
                @include max(991) {font-size: 22px;}
                @include max(767) { text-align: left; font-size: 16px;}
            }
        }
    }
}


.step1-wrap{ padding: 160px 0; min-height: 940px;
    @include max(991)  {padding: 80px 0;}
    @include max(991)  {display: none;}
    .content{ position: relative; 
        min-height: 610px;
        @include max(1199)  { min-height: 510px;}
        @include max(991)  { min-height: 400px;}
        .tip{font-size: 16px; font-weight: 400; color: #FD0095; background: url(../images/bulb.svg) no-repeat left center / 15px; padding: 2px 0 2px 20px;}
        h2{font-size: 88px; color: #000; font-weight: 700; line-height: 1.1;
            @include max(1199) {font-size: 71px;}
            @include max(991)  {font-size: 55px;}
        }
        .main{position: absolute; left: 0; top:37px; max-width: inherit;  pointer-events: none;
            @include max(1199) {max-width: 100%;}
        }
        .txt{color: #000; font-size: 9px; font-weight: 400; line-height: normal; position: absolute; left: 0px; top: 0px;
            @include max(991)  {font-size: 7px;}
            &.txt1{ width: 125px; left: 620px; top: 60px; display: none;
                @include max(1199) {top: 48px; left: 502px}
                @include max(991)  { width: 92px; top: 43px; left: 385px;}
            }
            &.txt2{ width: 110px; left: 820px; top: 460px; display: none;
                @include max(1199) {top: 380px; left: 670px;}
                @include max(991)  {width: 92px; top: 305px; left: 506px;}
            }
        }
    }

    .container{
        .magnifier-row{
            position: relative;

            .col5{

            }

            article{
                position: absolute;
                top: 0;
                right: 0;

            }
        }
    }
    // .zoom-wrap{margin: -210px 0 0 0;}
    // .loupe {
    //     display: none;
    //     position: absolute;
    //     width: 260px;
    //     height: 260px;
    //     // box-shadow: 5px 5px 12px black;
    //     cursor: none;
    //     overflow: hidden;
    //     border-radius: 50%;
    //     // background: white;
    //     // transition: all .5s ease; 
    // }

    // .loupe-svg {
    //     display: none;
    //     position: absolute;
    //     width: 200px;
    //     height: 200px;
    //     z-index: 1;
    //     cursor: none;

    // }

    // .loupe img {
    //     position: absolute;
    //     right: 0;
    // }


    .magnify { margin: 20px auto; display: inline-block; position: relative; } 
    .magnify .magnified { display: block; z-index: 10; margin: auto; width: 600px; height: 450px; border: 5px solid #fff; } 
    .magnify .magnifier { height: 200px; width: 200px; position: absolute; z-index: 20; border: 4px solid white; border-radius: 50%; background-size: 1000%; background-repeat: no-repeat; margin-left: -100px !important; margin-top: -100px !important; pointer-events: none; display: none; }

    
}

.step2-wrap{padding: 150px 0; background: #F3F3F3;
    @include max(991)  {padding: 80px 0;}
    @include max(767)  {display: none;}

    .connecting-container {
        position: absolute;
        top: -20px;
    }
    .content{position: relative; min-height: 590px;
        @include max(1199) {min-height: 405px;}
        @include max(991) {min-height: 315px;}
        .tip{font-size: 16px; font-weight: 400; color: #FD0095; background: url(../images/bulb.svg) no-repeat left center / 15px; padding: 2px 0 2px 20px;}
        h2{font-size: 88px; color: #000; font-weight: 700; line-height: 1.1;
            @include max(1199) {font-size: 54px;}
            @include max(991) {font-size: 47px;}
            &.one{margin: 10px 0 0 0;
                @include max(991) { margin: 0; font-size: 47px;}
            }
            &.two{position: absolute; top:370px; left: 240px;
                @include max(1199) { top: 260px; left: 310px;}
                @include max(991) {top: 200px; left: 240px;}
            }
        }

        figure {

            .step-2-svg {
                // position: relative;
                position: absolute;
                // right: 130px;
                // bottom: 300px;
                // right: 20;
                bottom: -104px;
                left: -132px;
            }
        }
        
        .main{position: absolute; right: 0; top:0; max-width: inherit; pointer-events: none;
            @include max(1199) {max-width: 100%;}
        }
        .txt{color: #000; font-size: 18px; font-weight: 400; line-height: 1.3; position: absolute; left: 0px; top: 0px;
            @include max(991) {font-size: 14px; }
            &.txt1{ width: 260px; 
                left: 510px; 
                // left: 490px; 
                top: -30px;
                @include max(1199) {top: -40px; left: 450px;}
                @include max(991) { width: 210px; left: 350px;}
            }
            &.txt2{ width: 195px; left: 1010px; top: 440px;
                @include max(1199) {width: 130px; top: 280px; left: 842px; font-size: 13px;}
                @include max(991) { width: 110px; left: 640px; top: 230px; font-size: 11px;}
            }
        }

        .step-2-txt {
            // opacity: 0;
        }
    }
}
.step3-wrap{padding: 110px 0;
    @include max(991)  {padding: 80px 0;}
    @include max(767)  {display: none;}
    .content{position: relative; min-height: 820px;
        @include max(991) {min-height: 560px;}
        .tip{font-size: 16px; font-weight: 400; color: #FD0095; background: url(../images/bulb.svg) no-repeat left center / 15px; padding: 2px 0 2px 20px;}
        h2{font-size: 88px; color: #000; font-weight: 700; line-height: 1.1;
            @include max(991) {font-size: 60px;}
        }
        .main{position: absolute; right: 30px; top:110px; max-width: inherit;  pointer-events: none;
            @include max(991) {max-width: 580px;}
        }
        .txt{color: #000; font-size: 18px; font-weight: 400; line-height: 1.3; position: absolute; left: 0px; top: 0px;
            opacity: 1;
            @include max(991) {font-size: 14px;}
            &.txt1{ width: 280px; left: 850px; top:280px;
                @include max(1199)  {left: 640px; }
                @include max(991) {left: 560px; top: 190px; width: 200px;}
            }
            &.txt2{ width: 280px; left: 730px; top: 500px;
                @include max(1199)  {left: 530px;}
                @include max(991) {left: 500px; top: 300px; width: 200px;}
            }
        }

        .step-3-txt {
            opacity: 0;
        }
    }
}


.step4-wrap{padding: 110px 0; background: #F3F3F3;
    @include max(991)  {padding: 80px 0;}
    @include max(767)  {display: none;}
    .content{position: relative;
        @include max(991){min-height: 550px;}
        .tip{font-size: 16px; font-weight: 400; color: #FD0095; background: url(../images/bulb.svg) no-repeat left center / 15px; padding: 2px 0 2px 20px;}
        h2{font-size: 88px; color: #000; font-weight: 700; line-height: 1.1;
            @include max(991) {font-size: 65px;}
        }
        .main{position: absolute; left: 0px; top:180px; max-width: inherit;  pointer-events: none;
            @include max(1199) {max-width: 100%;}
        }
        .txt{color: #000; font-size: 20px; font-weight: 400; line-height: 1.3; position: absolute; left: 0px; top: 0px;
            @include max(991) {font-size: 14px;}
            &.txt1{ width: 305px; left:260px; top:170px;
                @include max(1199) {width: 255px; top: 150px; left: 210px;}
                @include max(991) {width: 180px; top: 160px; left: 170px;}
            }
            &.txt2{ width: 260px; left: 760px; top: 130px;
                @include max(1199) {top: 150px; left: 610px;}
                @include max(991) {top: 170px;  left: 460px; width: 180px;}
            }
        }
    }
}

.step-1-mob-wrap{  padding: 80px 15px 50px 15px; position: relative;  overflow: hidden;
    @include max(359) {padding: 80px 0 50px 0;}
    .main{position: absolute; right: 15px; top: 25px; max-width: 97px;
        @include max(359) {max-width: 80px;}
    }
    .content{
        h2{color: #000; font-size: 25px; font-weight: 700; line-height: 1.2; padding: 0 0 50px 0;}
        p{font-size: 16px; color: #000; font-weight: 400; line-height: 1.2;}
        .txt1{ background: url(../images/mob-step-11-bg.png) no-repeat top left; background-size: 100%; width: 280px; height: 300px; padding: 30px 50px 0 25px; margin: 0 0 30px 0;}
        .txt2{ background: url(../images/mob-step-1-bg.png) no-repeat top left; background-size: 100%; width: 280px; height: 270px; padding:50px 50px 0 55px; margin: 0 0 0 40px;
            @include max(359) {margin: 0;}
        }
    }
}

.step-2-mob-wrap{  padding: 80px 15px 50px 15px; position: relative; background: #F3F3F3;  overflow: hidden;
    @include max(359) {padding: 80px 0 50px 0;}
    .main{position: absolute; right: 0; top: 15px; max-width: 80px;}
    .content{
        h2{color: #000; font-size: 25px; font-weight: 700; line-height: 1.2; padding: 0 0 25px 0;}
        p{font-size: 16px; color: #000; font-weight: 400; line-height: 1.2;}
        .txt1{padding: 0 0 20px 0;}
        .txt2{ }
        .main1{margin: 0 -30px 30px -30px;}
    }
}


.step-3-mob-wrap{  padding: 80px 15px 50px 15px; position: relative;   overflow: hidden;
    @include max(359) {padding: 80px 0 50px 0;}
    .main{position: absolute; right:20px; top: 30px; max-width: 80px;}
    .content{
        h2{color: #000; font-size: 25px; font-weight: 700; line-height: 1.2; padding: 0 0 25px 0;}
        p{font-size: 16px; color: #000; font-weight: 400; line-height: 1.2;}
        .txt1{padding: 0 0 30px 0;}
        .main1{ max-width: 250px; margin: 0 0 0 20px;}
    }
}

.step-4-mob-wrap{  padding: 80px 15px 50px 15px; position: relative; background: #F3F3F3; min-height: 460px; overflow: hidden;
    @include max(359) {padding: 80px 0 50px 0;}
    .main{position: absolute; right: 0; bottom: 20px; max-width: 150px; margin: 0 0px 0 0;}
    .content{
        h2{color: #000; font-size: 25px; font-weight: 700; line-height: 1.2; padding: 0 0 25px 0;}
        p{font-size: 16px; color: #000; font-weight: 400; line-height: 1.2;}
        .txt1{padding: 0 0 20px 0; max-width: 190px}
        .main1{margin: 0 -30px 30px -30px;}
    }
}
.stepmob-wrap{ display: none;
    @include max(767) {display: block;}
    .container{max-width: 360px;
        @include max(359) {max-width: 100%;}
    }
}
.step-1-mob-wrap{
    @include max(991) {display: block;}
}

.content-2{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 40px;
    
    @include max(767){
        display: none;
    }

    .tree-1{
        cursor: pointer;

        figure{
            img{
                max-width: 240px;
                height: 616px;
            }
        }
    }
    .tree-2{
        position: relative;
        cursor: pointer;

        .txt{
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.3;
            position: absolute;
            opacity: 0;
            // transition: all 0.5 ease;
        }

        .txt1{
            width: 305px;
            // top: 170px;
            // left: 260px;
            top: -30px;
        }

        figure{
            img{
                max-width: 178px;
                height: 568px;
            }
        }
    }
    .tree-3{
        cursor: pointer;
        figure{
            img{
                max-width: 219px;
                height: 617px;
            }
        }
    }
    .tree-4{
        position: relative;
        cursor: pointer;

        .txt{
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.3;
            position: absolute;
            opacity: 0;
            // transform: all .5 ease;
            
        }
        // .growing-plant-txt{
        //     // transition: opacity 2 ease;
        // }

        .txt2{
            width: 260px;
            top: -150px;
            // left: 760px;
        }
        

        figure{
            img{
                max-width: 180px;
                height: 488px;
            }
        }
    }
    .tree-5{
        cursor: pointer;

        figure{
            img{
                max-width: 228px;
                height: 615px;
            }
        }
    }
}



.all-step-wrap{
    h2{font-family: Manrope; font-size: 56px; font-weight: 700; line-height: 1.2; color: #FD0095; padding: 0 0 20px 0;
        br{
            @include max(991) { display: none; }
        }
        @include max(991) {font-size: 25px; font-weight: 700;}
    }
    ul{
        li{font-family: Manrope; color: #000; font-size: 18px; font-weight: 400; line-height: normal; list-style: disc; margin: 0 0 0 15px;
            @include max(991) {font-size: 16px;}
            +li{padding-top: 15px;}
        }
    }
    .flex-wrap{
        display: flex; justify-content: space-between; align-items: center;
        @include max(991) { flex-direction: column; gap: 20px;}
    }
    .txt{flex:0 0 340px;
        @include max(991) {flex: none;}
    }
    .img-wrap{
        img{max-width: none;}
    }
    .step-1-wrap{background: #FFF; padding: 120px 0;
        @include max(991) { padding: 50px 0;}
        .img-wrap{
            @include max(991) { display: none;}
            img{
                @include max(1199) { max-width: 100%;}
            }
        }
        .flex-wrap{
            @include max(1199) {gap: 20px;}
        }
        .img-mob{display: none;
            @include max(991) { display: block;}
        }
    }
    .step-2-wrap{background: #F3F3F3; padding: 90px 0;
        @include max(991) { padding: 50px 0;}
        .img-wrap{
            @include max(991) { display: none;}
            img{margin:-90px 0 0 -270px;
                @include max(1199) {max-width: 690px; margin: -100px 0 0 -70px;}
            }
        }
        .img-mob{display: none;
            @include max(991) { display: block;}
        }
    }
    .step-3-wrap{background: #FFF; padding: 120px 0;
        @include max(991) { padding: 50px 0;}
        .img-wrap{
            @include max(991) { display: none;}
        }
        .flex-wrap{
            @include max(1199) {gap: 20px;}
        }
        img{
            @include max(1199) { max-width: 100%;}
        }
        .img-mob{display: none;
            @include max(991) { display: block;}
        }
    }
    .step-4-wrap{background: #F3F3F3; padding: 90px 0;
        @include max(991) { padding: 50px 0;}
        .img-wrap{
            @include max(991) { display: none;}
        }
        .flex-wrap{
            @include max(1199) {gap: 20px;}
        }
        img{
            @include max(1199) { max-width: 100%;}
        }
        .img-mob{display: none;
            @include max(991) { display: block;}
        }
    }
}