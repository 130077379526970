.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;

    .cookie-popup{
        background: rgb(43, 55, 59);
        padding: 30px;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap:25px;


        p{
            text-align: center;
            color: #fff;

            a{
                color: #fff;
            }
        }

        .btn-wrap{
            display: flex;
            justify-content: center;
            gap: 20px;

            .btn{
                color: #fff;
                border: none;
                background: #fd0095;
                padding: 5px 10px;
            }

            .ebtn{
                background-color: transparent;
            }
        }
    }
}