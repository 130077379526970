@use "./variables" as *;


html{
    font-family: $fontFamily;
    font-size: 10px; overflow: scroll !important; overflow-x: hidden !important;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
}

body{
    background-color: #fff;
    height: auto !important; overflow-x: hidden !important;
}

img{
    height: auto;
    max-width: 100%;
}

p,span,a,table,div,article,figcaption{
    font-size: 1.7rem;
    font-weight: $fw300;
    line-height: 1.4;
}
h1,h2,h3,h4,h5,h6{
    font-family: $fontFamily;
    line-height: 1.4;
}
h1,.h1{
    font-size: 4.6rem;
}
h2,.h2{
    font-size: 3.5rem;
}
h3,.h3{
    font-size: 3rem;
}
h4,.h4{
    font-size: 2.5rem;
}
h5,.h5{
    font-size: 2.25rem;
}
h6,.h6{
    font-size: 2rem;
}

// a{
//     font-size: inherit;
//     @include transition;
// }