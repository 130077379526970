@font-face {
    font-family: "Manrope";
    src: url('/src/fonts/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    /* Light */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Manrope";
    src: url('/src/fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: 400;
    /* Regular */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Manrope";
    src: url('/src/fonts/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    /* Medium */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Manrope";
    src: url('/src/fonts/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    /* Bold */
    font-style: normal;
    font-display: swap;
}

/* Add more @font-face rules for other weights/styles as needed */