.m8, .tree-1, .tree-2, .tree-3, .tree-4, .tree-5 {
  /* Colors */
$primary: #FD0095; // yellow
$secondary: #FD0095; // prune
$secondary-70: #FD0095; // dark secondary
$secondary-20: #FD0095;

$stem-duration: 4s;

$slow-timing: 700ms;
$med-timing: 500ms;
$fast-timing: 300ms;

$t-cubic-bounce: cubic-bezier(0.24, 0.95, 0.89, 1.18);

@mixin animateDash($duration, $direction: normal) {
  animation: dash $duration $direction forwards;
}

@mixin dashArray($dashvalue) {
  stroke-dasharray: $dashvalue;
  stroke-dashoffset: $dashvalue;
}

@media (prefers-reduced-motion: reduce) {
  .stars,
  .sparkles {
    opacity: 0;
    transition: opacity 3s ease-in;
  }

  .plantAndLeaves {
    opacity: 0;
    transition: opacity 3s ease-in;
  }

  .animate {
    .plantAndLeaves {
      opacity: 1;
      transition: opacity 3s ease-in;
    }

    .stars {
      opacity: 1;
      transition: opacity 3s ease-in;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .stem {
    stroke-width: 2.44px;
    @include dashArray(600);
  }

  .branch-small {
    @include dashArray(26);
  }

  .branch-medium {
    @include dashArray(220);
    // @include dashArray(34);
  }

  .animate {
    .stem {
      @include animateDash($stem-duration);
      animation-timing-function: linear;
    }

    .branch-small {
      @include animateDash(0.8s);
      animation-timing-function: cubic-bezier(0, 0, 0.57, 1.02);
    }

    .branch-medium {
      @include animateDash(1s);
      animation-timing-function: cubic-bezier(0, 0, 0.57, 1.02);
    }

    .branch-01-right {
      animation-delay: $stem-duration/42;
    }

    .leaf-01-right {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/30;
    }

    .branch-01-left {
      animation-delay: $stem-duration/17;
    }

    .leaf-01-left {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/12;
    }

    .branch-02-right {
      animation-delay: $stem-duration/8;
    }

    .leaf-02-right {
      animation: leafbigger $slow-timing forwards;
      animation-delay: $stem-duration/6;
    }

    .branch-02-left {
      animation-delay: $stem-duration/5.1;
    }

    .leaf-02-left {
      animation: leafbigger $slow-timing forwards;
      animation-delay: $stem-duration/4.7;
    }

    .branch-03-right {
      animation-delay: $stem-duration/4;
    }
    .leaf-03-right {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/3.6;
    }

    .branch-04-right {
      animation-delay: $stem-duration/2.9;
    }
    .leaf-04-right {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/2.5;
    }

    .branch-03-left {
      animation-delay: $stem-duration/2.4;
    }
    .leaf-03-left {
      animation: leafbigger $slow-timing forwards;
      animation-delay: $stem-duration/2.3;
    }

    .branch-05-right {
      animation-delay: $stem-duration/2;
    }

    .leaf-05-right {
      animation: leafbigger $slow-timing forwards;
      animation-delay: $stem-duration/2;
    }

    .branch-04-left {
      animation-delay: $stem-duration/1.72;
    }
    .leaf-04-left {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/1.72;
    }

    .branch-06-right {
      animation-delay: $stem-duration/1.42;
    }

    .leaf-06-right {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/1.4;
    }

    .branch-05-left {
      animation-delay: $stem-duration/1.15;
    }
    .leaf-05-left {
      animation: leafbigger $med-timing forwards;
      animation-delay: $stem-duration/1.15;
    }

    .leaf-final-mid {
      animation: leafbigger $fast-timing forwards;
      animation-delay: $stem-duration/1.06;
    }

    .leaf {
      animation-timing-function: $t-cubic-bounce;
    }

    // success animation at the end

    .stars {
      animation: opacityAppear $fast-timing forwards;
      animation-delay: $stem-duration;
      animation-timing-function: $t-cubic-bounce;
    }
    .star-01 {
      animation: pulse 3s infinite;
      animation-delay: $stem-duration + 0.7s;
    }

    .star-02 {
      animation: pulse 3s infinite;
      animation-delay: $stem-duration + 1.2s;
    }

    .star-03 {
      animation: pulse 3s infinite;

      animation-delay: $stem-duration + 1.5s;
    }

    .sparkles .line {
      animation: dashSparkle 0.4s ease-in-out;
      animation-delay: $stem-duration;
    }
  }

  .stars {
    opacity: 0;
  }

  .leaf {
    opacity: 0.8;
  }

  .leaf-01-right {
    transform: translate(-7px, 7px) scale(0);
    transform-origin: 45.79px 420.83px;
  }

  .leaf-01-left {
    transform: translate(7px, 7px) scale(0);
    transform-origin: 29.75px 399.8px;
  }

  .leaf-02-right {
    transform: translate(-10px, 10px) scale(0);
    transform-origin: 53px 364.68px;
  }

  .leaf-02-left {
    transform: translate(7px, 7px) scale(0);
    transform-origin: 28.22px 341.98px;
  }

  .leaf-03-right {
    transform: translate(-7px, 7px) scale(0);
    transform-origin: 45.58px 304.49px;
  }

  .leaf-04-right {
    transform: translate(-3px, 3px) scale(0);
    transform-origin: 49.1px 250.92px;
  }

  .leaf-03-left {
    transform: translate(10px, 7px) scale(0);
    transform-origin: 30.13px 217.64px;
  }

  .leaf-05-right {
    transform: translate(-10px, 10px) scale(0);
    transform-origin: 48.51px 173.52px;
  }

  .leaf-04-left {
    transform: translate(7px, 7px) scale(0);
    transform-origin: 33.35px 139.12px;
  }

  .leaf-06-right {
    transform: translate(-3px, 3px) scale(0);
    transform-origin: 58.97px 78.99px;
  }

  .leaf-05-left {
    transform: translate(7px, 7px) scale(0);
    transform-origin: 33.58px 68.03px;
  }

  .leaf-final-mid {
    transform-origin: 39.17px 32.83px;
    transform: translate(0px, 5px) scale(0);
  }

  .star-01 {
    transform-origin: 54.26px 7.95px;
  }

  .star-02 {
    transform-origin: 12.9px 16.6px;
  }

  .star-03 {
    transform-origin: 63.06px 36.95px;
  }

  .sparkles .line {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dashSparkle {
    to {
      stroke-dashoffset: -7;
    }
  }

  @keyframes opacityAppear {
    to {
      opacity: 1;
    }
  }

  @keyframes leafbigger {
    to {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
  }
}

// SVG colors code
// SVG colors code
.primary-filled {
  fill: $primary;
}
.primary-border {
  fill: none;
  stroke: $primary;
  stroke-width: 2.44;
  stroke-linecap: round;
}

.stem {
  stroke-width: 2.44px;
  fill: none;
  stroke: $secondary-70;
  stroke-linecap: round;
}

.secondary-border {
  fill: $secondary-70;
}

.branch,
.heart-border {
  fill: none;
  stroke-linecap: round;
}

.branch {
  stroke: $secondary-70;
  stroke-width: 2.44px;
}

.pot-bg {
  fill: $secondary-20;
}

.heart-bg {
  fill: #fff;
}

.pot-border {
  fill: $secondary;
}

.heart-border {
  stroke: $secondary;
  stroke-linejoin: round;
  stroke-width: 2.9px;
}

svg {
 //border: 1px solid blue; 
  display: block;
  // height: 60vh;
  max-width: 100%;
  margin: 0 auto;
}

// body {
//   font-family: 'Raleway', sans-serif;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   width: 100vw;
//   margin: 0;
//   padding: 0;

//   background-color: #fcfcfc;
//   opacity: 1;
//   background-image: radial-gradient(#e6e6e6 0.8px, transparent 0.8px),
//     radial-gradient(#e6e6e6 0.8px, #fcfcfc 0.8px);
//   background-size: 32px 32px;
//   background-position: 0 0, 16px 16px;
// }

.container {
  width: 120px;
 width:100%;
  
}
.theplant {
  max-width:350px;
  margin-top: auto;
  margin-bottom: auto;
}

.details {
  background: $primary;
  color: $secondary-70;
  align-self: stretch; /* default auto */
  text-align: center;
  padding: 1em;
}



$bgPositionOut: 400px 210px;
$bgPositionIn: 100% 100%;

.button {
  font-family: 'Raleway', sans-serif;
  background-color: transparent;
  background-image: linear-gradient(to right, $primary, $primary);
  background-position: $bgPositionOut;
  background-repeat: no-repeat;
  background-size: calc(100% - 5px) calc(100% - 5px);

  font-size: 1em;
  font-weight: bold;
  color: $secondary-70;
  letter-spacing: -0.005em;
  position: relative;
  display: inline-block;
  border: 0 none;
  z-index: 1;
  min-width: 150px;
  max-width: 100%;
  margin: 0.75em 0.33em;
  margin-top: 3em;
  padding: 0.4em 1.3em;
  cursor: pointer;
  span:before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 2px solid $secondary-70;
    border-radius: 3px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -6px;
    right: -6px;
    background: $primary;
    z-index: -1;
  }
  &:before {
    left: 5px;
    height: 8px;
  }
  &:after {
    top: 5px;
    width: 8px;
  }
}

.button.inverted{
  background-image: linear-gradient(to right, $secondary-20, $secondary-20);
  &:before,
  &:after {
    background: $secondary-20;
  }
}

.disabled {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: all !important;
}

.button {
  &:hover,
  &:focus {
    outline: 0;
    background-color: transparent;
    background-position: $bgPositionIn;
  }
  &:active {
    transform: scale(.9);
  }

  span {
    > * {
      display: inline-block;
      vertical-align: center;
    }
  }

  &.is-light {
    min-width: auto;
    padding: 0 0 0 0;
    border-bottom: 0;

    &:before,
    &:after {
      display: none;
    }

    &:hover,
    &:focus {
      background-position: 7px 18px;
    }
  }
}
.button,
input,
select,
textarea {
  transition: all 0.275s;
  text-decoration: none;
}
 
}

.step-4 {

  .latter-p {
    // position: relative;
    position: relative;
    bottom: 93px;
    left: 95px;
  }
  .latter-r {
    // position: relative;
    position: relative;
    bottom: 93px;
    left: 80px;
  }
  .latter-o {
    // position: relative;
    position: relative;
    bottom: 93px;
    left: 54px;
  }
  .latter-v {
    // position: relative;
    position: relative;
    bottom: 93px;
    left: 61px;
  }
  .latter-e {
    // position: relative;
    position: relative;
    bottom: 93px;
    left: 65px;
  }


}